import React from 'react';
import { PulseLoader } from 'react-spinners';
import classNames from 'classnames';

const LoadingSpinner = ({message, absolute}) => (
  <div className={classNames('loading-spinner', {absolute: absolute})}>
    <PulseLoader css={{'text-align': 'center'}} color="#027aff" />
    <p>{message ? message : 'لطفا صبر کنید'}</p>
  </div>
);

export default LoadingSpinner;
