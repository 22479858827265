import aesJS from 'aes-js'

export function toEnglishDigits(str) {

  // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
  var e = '۰'.charCodeAt(0);
  str = str.replace(/[۰-۹]/g, function(t) {
    return t.charCodeAt(0) - e;
  });

  // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
  e = '٠'.charCodeAt(0);
  str = str.replace(/[٠-٩]/g, function(t) {
    return t.charCodeAt(0) - e;
  });
  return str;
}

export function encryptAppId(id) {
  const textBytes = aesJS.utils.utf8.toBytes(id);

  let key = [146, 178, 59, 176, 30, 114, 161, 243, 51, 157, 28, 238, 202, 63, 78, 6, 174, 103, 98, 250, 251, 22, 168, 41, 24, 216, 146, 229, 43, 155, 232, 254]

  const aesCbc = new aesJS.ModeOfOperation.cbc(key);
  const encryptedBytes = aesCbc.encrypt(aesJS.padding.pkcs7.pad(textBytes));

  return aesJS.utils.hex.fromBytes(encryptedBytes)
}