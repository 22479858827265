import BaseService from './BaseService';

class DownloadService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  requestDownload = (phoneNumber, callback) => {
    this.httpClient.post('/installer/requestDownload', {
      phoneNumber,
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  authorizeDownload = (phoneNumber, downloadId, activationCode, callback) => {
    this.httpClient.put(`/installer/authorizeDownload/${phoneNumber}/${downloadId}`, {
      activationCode,
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };
}

export default DownloadService;
