import React, { Component } from "react";
import FormGroup from "reactstrap/es/FormGroup";
import Input from "reactstrap/es/Input";
import Label from "reactstrap/es/Label";
import { withRouter } from "react-router-dom";

import Api from "../networking/Api";

const WEB_API_BASE_URL = process.env.REACT_APP_IAPPS_API_BASE_URL;

class InstallKit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didPressKitButton: false,
    };
  }

  onKitDownloadClicked = () => {
    const { history } = this.props;

    const kitAppId =
      "291641ea-1cca-4019-82c8-44462f88e6a6-55913516-1277-411c-8005-cd6bee23bf89";
    const kitAppVersionId =
      "5a338bf5-bd68-4017-9904-92b35abd5345-31584df9-9780-4a95-8f9f-f34d5027d0f6";
    let downloadUrl = "";
    Api().appInstallerService.requestDownload(
      kitAppId,
      "enterprise",
      true,
      null,
      kitAppVersionId,
      {
        success: (response) => {
          if (response.token) {
            try {
              localStorage.setItem("did_click_kit_installation_button", "true");
            } catch (e) {}
            downloadUrl = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
            window.location.href = downloadUrl;

            this.setState({
              didPressKitButton: true,
            });
          } else if (response.status === 402) {
            history.push({
              pathname: "/packages",
              state: { checkUserAuthentication: false },
            });
          }
        },
        error: () => {},
      }
    );
  };

  onDownloadAppClicked = () => {
    const { app, history } = this.props;
    const { skipKitNextTime } = this.state;

    if (skipKitNextTime === true) {
      try {
        localStorage.setItem("skip_kit_installation_2", "true");
      } catch (e) {}
    }

    Api().appInstallerService.requestDownload(
      app.externalId,
      "enterprise",
      true,
      null,
      app.appVersion.externalId,
      {
        success: (response) => {
          if (response.token) {
            const url = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
            window.location = url;
          } else if (response.status === 402) {
            history.push("/packages/enterprise");
          }
        },
        error: () => {
          // TODO: handle error
        },
      }
    );
  };

  setSkipKitInstallation = (e) => {
    this.setState({
      skipKitNextTime: e.target.checked,
    });
  };

  render() {
    const { app } = this.props;
    const { didPressKitButton } = this.props;

    const didPressKitButtonLocalStorage = localStorage.getItem(
      "did_click_kit_installation_button"
    );

    return (
      <div className="download-kit">
        <p className="step">مرحله اول</p>
        <img className="kit-logo" src="/images/logo-iapps-kit.png" alt="" />
        <p>
          برای اجرای برنامه ها به صورت عادی{" "}
          <b>لازم است ابتدا آی اپس کیت را نصب کنید</b>
        </p>

        <button
          className="install-kit-button"
          onClick={this.onKitDownloadClicked}
        >
          نصب «آی اپس کیت»
        </button>

        <FormGroup>
          <Label>آی اپس کیت را نصب کرده ام دیگر این گزینه را نشان نده</Label>
          <Input type="checkbox" onChange={this.setSkipKitInstallation} />
        </FormGroup>

        <div className="next-step">
          <p className="step">مرحله دوم</p>
          <button
            disabled={
              didPressKitButtonLocalStorage !== "true" || didPressKitButton
            }
            onClick={this.onDownloadAppClicked}
          >
            نصب «{app.appDetail.title}»
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(InstallKit);
