import React from "react";
import { Link } from "react-router-dom";

const NoSubscriptionPackages = () => (
  <div className="no-subscription-packages">
    <Link to="/packages">
      <img src="/images/character-vip-service.svg" alt="سرویس اشتراکی iApps" />
    </Link>
    <p>در حال حاضر سرویس اشتراکی فعال ندارید.</p>
    <Link to="/packages" className="button">
      خرید اشتراک
    </Link>
  </div>
);

export default NoSubscriptionPackages;
