import React, { Component } from 'react';
import Ratings from 'react-ratings-declarative';
import FormGroup from 'reactstrap/es/FormGroup';
import Input from 'reactstrap/es/Input';
import { PulseLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import Api from '../../shared/networking/Api';
import { connect } from 'react-redux';

class SubmitReview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentReview: null,
      commentRating: 0,
      buttonLoading: false,
      reviewTitle: '',
      reviewComment: '',
    };
  }

  componentDidMount() {
    const { match, toggleLoginView, history } = this.props;
    if (match.params && match.params.appId) {
      const appId = match.params.appId;

      Api().reviewService.getCurrentReview(appId, {
        success: (review) => {
          this.setState({
            currentReview: review,
            commentRating: review.rate,
            reviewTitle: review.title,
            reviewComment: review.comment,
            loading: false,
          });
        },
        error: (code) => {
          if (code === 401) {
            toggleLoginView(true);
            history.goBack();
            return;
          }

          this.setState({
            loading: false,
          });
        },
      });
    } else {
      // TODO: show not found error
    }
  }

  submitRatingAndComment = (e) => {
    e.preventDefault();

    this.setState({
      buttonLoading: true,
    });

    const { history, match } = this.props;
    if (match.params && match.params.appId) {
      const appId = match.params.appId;

      const { commentRating, reviewTitle, reviewComment } = this.state;
      if (commentRating === 0) {
        this.setState({
          error: 'امتیاز مورد نظر را انتخاب کنید',
        });
        return;
      }

      Api().reviewService.addAppReview({
        rate: commentRating,
        title: reviewTitle,
        comment: reviewComment,
        appId: appId,
      }, {
        success: () => {
          history.goBack();
          toast.success('نظر شما با موفقیت ثبت شد');
        },
        error: () => {
          toast.error('خطا در ثبت نظر لطفا مجددا تلاش کنید');
          this.setState({
            buttonLoading: false,
          })
        },
      });
    }
  };

  onBackButtonClicked = (e) => {
    e.preventDefault();

    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { loading, commentRating, reviewTitle, reviewComment, buttonLoading, currentReview, submitLoading } = this.state;
    return (
      <div className="submit-review">
        <div className="header">
          <a href="#" onClick={this.onBackButtonClicked}><img src="/images/icon-back.svg" alt="" /></a>
          <h1 className="title">ارسال نظر</h1>
        </div>
        { loading ? <LoadingSpinner /> :
          <form onSubmit={this.submitRatingAndComment}>
            <Ratings
              rating={commentRating === 0 ? (currentReview ? currentReview.rate : 0) : commentRating}
              changeRating={(rating) => this.setState({commentRating: rating})}
              widgetDimensions="32px"
              widgetSpacings="2px"
              widgetRatedColors="#ff9502"
            >
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
              <Ratings.Widget />
            </Ratings>

            <FormGroup>
              <Input type="text" placeholder="عنوان (اختیاری)" value={reviewTitle !== '' ? reviewTitle : (currentReview ? currentReview.title : '')} onChange={(e) => {this.setState({
                reviewTitle: e.target.value,
              })}} />
            </FormGroup>
            <FormGroup>
              <Input type="textarea" placeholder="متن نظر (اختیاری)" value={reviewComment !== '' ? reviewComment : (currentReview ? currentReview.comment : '')} onChange={(e) => {this.setState({
                reviewComment: e.target.value,
              })}} />
            </FormGroup>
            <button type="submit" onClick={this.submitRatingAndComment} disabled={buttonLoading}>{buttonLoading ? <PulseLoader css={{'text-align': 'center'}} color="#fff" size="8" /> : 'ارسال'}</button>
          </form>
        }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) => dispatch({type: 'LOGIN_REQUIRED', isLoginRequired,}),
  };

}

export default withRouter(connect(null, mapDispatchToProps)(SubmitReview));
