import React from 'react';
import { Link } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { getImageUrlForFileId } from '../../../shared/networking/Api';

const CategoriesSection = ({section}) => {

  return (
    <div className="categories-section">
      <div className="section-header">
        <h1>{section.title}</h1>
        <Link to="/category">بیشتر</Link>
      </div>
      {section.categoryListItems.map((li =>
        <Link to={
          {
            pathname: `category/${li.category.externalId}`,
            state: {
              sectionTitle: li.category.title,
            }
          }
        } key={li.category.externalId} 
        onClick={() => {ym('reachGoal', 'CATEGORY_CLICK', {
          SectionsUserInteractions: {Categories: li.category.title}
        })}}
        >
          <div className="category-item">
            <img src={getImageUrlForFileId(li.category.iconFileId, 60, 60)} alt={li.category.title} />
            <p>{li.category.title}</p>
          </div>
        </Link>))
      }
    </div>
  );
};

export default CategoriesSection;
