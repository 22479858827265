import React, { useState, useEffect, useCallback } from "react";
import Lottie from "react-lottie-player";
import moment from "moment-timezone";
import potter from "./potter.json";
import { useHistory } from "react-router-dom";
import Api from "../networking/Api";

export const currentApp = {
  id: "/i/810802321",
  content: "سری کامل فیلم‌های هری‌پاتر با دوبله‌ی اختصاصی از فیلیمو!",
  title: "",
};

const MiniCampaignPopup = function () {
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const TehranTimezone = "Asia/Tehran";

  // Set the start and end times in Tehran time
  const startTime = moment.tz("2023-04-23 12:00", TehranTimezone);
  const endTime = moment.tz("2023-04-24 10:00", TehranTimezone);

  // Get the current time in Tehran time
  const currentTime = moment.tz(TehranTimezone);

  // Check if the current time is within the start and end times
  const isWithinTimeRange = currentTime.isBetween(startTime, endTime);

  useEffect(() => {
    const isSeen = localStorage.getItem(`mini-campaign-dialog`);

    if (isWithinTimeRange && !isSeen) {
      setShowModal(true);
    }
  }, []);

  const closeHandler = useCallback(() => {
    setShowModal(false);
    localStorage.setItem(`mini-campaign-dialog`, JSON.stringify(true));
  }, []);

  if (showModal)
    return (
      <div
        id="modal-with-gif-container"
        class="modal-with-gif-container"
        onClick={closeHandler}
      >
        <div className="modal-with-gif" id="modal-with-gif">
          <div className="header">
            <img
              src="/images/iapps-logo-typography-blue.png"
              alt="iApps logo"
            />
            <a
              id="closeButton"
              href="#closeButton"
              onClick={(e) => {
                e.preventDefault();
                closeHandler();
              }}
            >
              <img
                id="close-button-image"
                src="/images/campagin-modal-close-icon.png"
                alt="close button"
              />
            </a>
          </div>
          <div className="content">
            <div className="gif-container">
              <Lottie
                animationData={potter}
                play
                loop
                style={{
                  width: "70%",
                  margin: "auto",
                  marginTop: "40px",
                  paddingBottom: "78px",
                }}
              />
            </div>
            <div className="main-content">
              {/* <p className="badge">فقط برای امروز</p> */}
              <h1>
                سری کامل فیلم‌های <span className="blue">هری‌پاتر</span> با
                دوبله‌ی اختصاصی از فیلیمو!{" "}
              </h1>
              {/* <div className="tip-container">
                <img
                  width="29px"
                  height="50px"
                  src="/images/hand_two_fingers.svg"
                  alt="hand_two_fingers"
                />
                <div className="tip">فرصت ویژه، همین الآن!</div>
              </div> */}
              <button
                style={{ marginTop: "26px", color: "white" }}
                onClick={() => history.push("/i/810802321")}
                id="mini_campaign_popup"
              >
                برو بریم!
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return null;
};

export default React.memo(MiniCampaignPopup);
