import BaseService from "./BaseService";

class DeveloperService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  getDeveloperPublicInfo = (username, callback) => {
    this.httpClient
      .get(`/developers/accounts/${username}/publicInfo`)
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  getDistributionTypes = (appId, callback) => {
    this.httpClient
      .get(`/developers/apps/${appId}/distributionTypes`)
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  incrementViewCount = (appId, callback) => {
    this.httpClient
      .post(`/developers/apps/${appId}/views/increment`)
      .then((response) => this.onResponse(response, callback))
      .catch((error) => this.onError(error, callback));
  };
}

export default DeveloperService;
