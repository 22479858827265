import React, { Component } from 'react';
import TriadAppItem from './TriadAppItem';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import { isMobile } from '../../../shared/DeviceUtils';

class TriadAppsSection extends Component {

  componentDidMount() {
    let options = {};
    if (isMobile()) {
      options = {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 13,
      };
    } else {
      options = {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      };
    }
    const mySwiper = new Swiper('.triad-swiper-container', options);
  }

  render() {
    const { section, all, onDownloadButtonClicked } = this.props;
    const appItems = [];
    if (section.appListingSection) {
      const appsCount = section.appListingSection.apps.length;
      let appGroup = [];
      section.appListingSection.apps.forEach((app, index) => {
        if (appGroup.length < 3) {
          appGroup.push(<TriadAppItem bordered={true} key={app.externalId} app={app} onDownloadButtonClicked={onDownloadButtonClicked} sectionName={section.title} />);
        } else {
          appItems.push(<div key={app.externalId + index} className="app-group swiper-slide">{appGroup}</div>);
          appGroup = [];
          appGroup.push(<TriadAppItem bordered={true} key={app.externalId} app={app} onDownloadButtonClicked={onDownloadButtonClicked} sectionName={section.title} />);
        }

        if(!all){
          if (index === appsCount - 1) {
            if (appsCount % 3 !== 0 || appsCount === 3) {
              appItems.push(<div key={app.externalId} className="app-group swiper-slide">{appGroup}</div>);
            }
          }}
      });
    }

    return (
      <div className="triad-section">
        <div className="section-header">
          <h1>{section.title}</h1>
          <Link to={
            {
              pathname: `/apps/${section.id}`,
              state: {
                sectionTitle: section.title,
              }
            }}>بیشتر</Link>
        </div>
        <div className="triad-swiper-container">
          <div className="swiper-wrapper">
            { appItems }
          </div>
        </div>

        <hr className="separator" />
      </div>
    );
  }
}

export default TriadAppsSection;
