import base64 from "base-64";
import utf8 from "utf8";
import BaseService from "./BaseService";
import jsCookie from "js-cookie";

class AuthService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  generate = (phoneNumber, callback) => {
    this.httpClient
      .post("/accounts/otp/generate", {
        phoneNumber,
      })
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  requestToken = (username, code, callback) => {
    this.httpClient
      .post(
        "/uaa/oauth/token",
        `scope=ui&grant_type=otp&username=${encodeURIComponent(
          username
        )}&otp_code=${code}`,
        {
          headers: {
            Authorization: `Basic ${base64.encode(
              utf8.encode(
                `pwa:${process.env.REACT_APP_BROWSER_CLIENT_PASSWORD}`
              )
            )}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          window.dataLayer.push({ event: "login_success", username });
          this.onResponse(response, callback);
        }
        this.onError(response, callback);
      })
      .catch((error) => {
        window.dataLayer.push({ event: "login_fail", username });
        this.onError(error, callback);
      });
  };

  refreshToken = (refreshToken, callback) => {
    this.httpClient
      .post(
        "/uaa/oauth/token",
        `scope=ui&grant_type=refresh_token&refresh_token=${refreshToken}`,
        {
          headers: {
            Authorization: `Basic ${base64.encode(
              utf8.encode(
                `browser:${process.env.REACT_APP_BROWSER_CLIENT_PASSWORD}`
              )
            )}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          jsCookie.set("TOKEN", response.data.access_token, { expires: 365 });
          jsCookie.set("REFRESH_TOKEN", response.data.refresh_token, {
            expires: 365,
          });
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  currentAccount = (callback) => {
    this.httpClient
      .get("/uaa/users/current", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };
}

export default AuthService;
