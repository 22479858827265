import React, { Component } from "react";
import { Link } from "react-router-dom";
import ym from "react-yandex-metrika";
import Banner from "./Banner";
import Swiper from "swiper";
import { isMobile } from "../../../shared/DeviceUtils";

class BannerSection extends Component {
  componentDidMount() {
    let options = {};
    if (isMobile()) {
      options = {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 12,
      };
    } else {
      options = {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      };
    }

    const mySwiper = new Swiper(".swiper-container", options);
  }

  render() {
    const { section } = this.props;

    const items = [];

    if (section.bannerSectionItems) {
      section.bannerSectionItems.forEach((bannerItem) => {
        items.push(<Banner key={bannerItem.id} banner={bannerItem} />);
      });
    }

    return (
      <div className="banner-section">
        <div className="scroller swiper-container">
          <div className="swiper-wrapper">{items}</div>
        </div>
        <hr className="separator" />
      </div>
    );
  }
}

export default BannerSection;
