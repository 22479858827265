const sectionsReducer = (state = {
  homeSections: null,
  appSections: null,
  gameSections: null,
}, action) => {

  switch(action.type) {
    case 'HOME':
      return { ...state, homeSections: action.sections, };

    case 'APP':
      return { ...state, appSections: action.sections, };

    case 'GAME':
      return { ...state, gameSections: action.sections, };

    default:
      return state;
  }
};

export default sectionsReducer;