import BaseService from './BaseService';

class ReviewService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  getAppRate = (appId, callback) => {
    this.httpClient.get(`/reviews/appRates/${appId}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  getAppComments = (appId, callback) => {
    this.httpClient.get(`/reviews/apps/${appId}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  addAppReview = (review, callback) => {
    this.httpClient.post(`/reviews/`, review).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  getCurrentReview = (appId, callback) => {
    this.httpClient.get(`/reviews/current?appId=${appId}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };
}

export default ReviewService;
