import Cookie from 'js-cookie';
import { setAuthToken } from '../shared/AccountManager';

export function migrate() {
  const currentVersion = localStorage.getItem('CLIENT_VERSION');
  if (currentVersion) {
    switch (parseInt(currentVersion)) {
      case 2:
        break;
      default:

    }
  } else {
    // Version 1
    const token = Cookie.get('TOKEN');
    const refreshToken = Cookie.get('REFRESH_TOKEN');

    if (token || refreshToken) {
      setAuthToken(token, refreshToken);
      Cookie.remove('TOKEN');
      Cookie.remove('REFRESH_TOKEN');
    }

    const mobileInfoToken = Cookie.get('MOBILE_CONFIG_INFO_TOKEN');
    if (mobileInfoToken) {
      try {
        localStorage.setItem('MOBILE_CONFIG_INFO_TOKEN', mobileInfoToken);
      } catch (e) {
      }
      Cookie.remove('MOBILE_CONFIG_INFO_TOKEN', { path: '', })
    }

    try {
      localStorage.setItem('CLIENT_VERSION', '2');
    } catch (e) {
    }
  }
}
