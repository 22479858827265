const userReducer = (state = {
  isLoginRequired: false,
}, action) => {

  switch(action.type) {
    case 'LOGIN_REQUIRED':
      return { ...state, isLoginRequired: action.isLoginRequired, };
    default:
      return state;
  }
};

export default userReducer;