import React from 'react';

const NetworkErrorMessage = ({ title, message, buttonTitle, buttonAction }) => (
  <div className="network-error-message">
    <div className="content">
      <p className="title">{title}</p>
      <p className="message">{message}</p>
      <a href="#" className="retry-button" onClick={(e) => {e.preventDefault(); buttonAction();}}>{buttonTitle}</a>
    </div>
  </div>
);

NetworkErrorMessage.defaultProps = {
  title: 'خطا',
  message: 'خطا در ارتباط با سرور',
  buttonTitle: 'تلاش مجدد',
};

export default NetworkErrorMessage;
