import BaseService from './BaseService';
import { encryptAppId } from '../StringUtils';

class AppInstallerService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  requestDownload = (appExternalId, param, useCredit, deviceInfoToken, appVersionId, callback) => {
    this.httpClient.post(`/appInstaller/requestDownload/${param}`, {
      useCredit,
      encryptedAppExternalId: encryptAppId(appExternalId),
      deviceInfoToken
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  checkDownloadRequested = (appId, appVersionId, callback) => {
    this.httpClient.get(`/appInstaller/checkDownloadRequested/${appId}/${appVersionId}`, {
      headers: {
        'X-API-Version': process.env.REACT_APP_API_VERSION,
        'X-Client-Version-Code': process.env.REACT_APP_CLIENT_VERSION_CODE,
      },
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };
}

export default AppInstallerService;
