import _ from 'lodash';
import base64 from 'base-64';
import utf8 from 'utf8';
import axios from 'axios';
import { getAuthRefreshToken, setAuthToken } from '../AccountManager';
import { removeUserData } from '../../containers/account/Profile';

const API_BASE_URL = process.env.REACT_APP_IAPPS_API_BASE_URL;

class BaseService {
  onResponse = (response, callback) => {
    if (response.status >= 200 && response.status < 300) {
      callback.success(response.data, response.status);
    } else if (response.data && response.data.error && response.data.error.message) {
      callback.error(response.status, response.data.error.message);
    } else if (callback.has('error')) {
      callback.error(response.status);
    }
  };

  onError = (error, callback) => {
    if (error && error.response && error.response.status === 401) {
      // needs login
      const httpClient = axios.create({
        baseURL: API_BASE_URL,
      });
      httpClient.post('/uaa/oauth/token', `scope=ui&grant_type=refresh_token&refresh_token=${getAuthRefreshToken()}`, {
        headers: {
          Authorization: `Basic ${base64.encode(utf8.encode(`pwa:${process.env.REACT_APP_BROWSER_CLIENT_PASSWORD}`))}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setAuthToken(response.data.access_token, response.data.refresh_token);
        } else {
          removeUserData();
        }
      }).catch(() => {
        removeUserData();
      });
    }

    if (_.has(callback, 'error')) {
      callback.error(error && error.response && error.response.status ? error.response.status : null, error && error.response && error.response.data && error.response.data.error ? error.response.data.error.message : error && error.response && error.response.data ? error.response.data : error.message);
    }
  };
}

export default BaseService;
