import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClassNames from "classnames";
import ym from "react-yandex-metrika";

import { getImageUrlForFileId } from "../../../shared/networking/Api";
import DownloadButton from "../../../shared/components/DownloadButton";
import AppDownloaderV2 from "../../../shared/components/AppDownloaderV2";
import IOS16DeveloperMode from "../../../shared/components/IOS16DeveloperMode";

const TriadAppItem = ({
  app,
  bordered,
  onDownloadButtonClicked,
  classNames,
  sectionName,
}) => {
  return (
    <div className={ClassNames("triad-app", classNames)}>
      <Link
        to={`/i/${app.externalNumericId}`}
        onClick={() => {
          ym("reachGoal", "TRIAD_APP_CLICK", {
            SectionsUserInteractions: { [sectionName]: app.appDetail.title },
          });
        }}
      >
        <div className="icon-wrapper">
          <img
            className="icon"
            src={getImageUrlForFileId(app.appDetail.iconFileId, 118, 118)}
            alt={app.appDetail.title}
          />
          {app.saleType !== "FREE" && (
            <img
              className="icon-badge"
              src={"/images/icon-vip.svg"}
              alt={app.appDetail.title}
            />
          )}
        </div>
        <div className="info">
          <p className="title">{app.appDetail.title}</p>
          <p className="category">{app.category.title}</p>
        </div>
      </Link>
      <AppDownloaderV2 app={app} lightVariant />
      {/* <DownloadButton app={app} bordered={bordered} action={onDownloadButtonClicked} /> */}
    </div>
  );
};

export default TriadAppItem;
