import React from 'react';
import classNames from 'classnames';

const Header = ({ title, small }) => (
  <div className={classNames('header', {small: small})}>
    <h1>{title}</h1>
    <hr className="separator" />
  </div>
);

export default Header;
