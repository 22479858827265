import React, { Component } from "react";
import { PulseLoader } from "react-spinners";
import Api from "../../../shared/networking/Api";

class DownloadProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  downloadProfile = () => {
    const { onProfileDownloaded } = this.props;

    this.setState({
      loading: true,
    });

    const mobileInfoToken = localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN");

    if (mobileInfoToken) {
      window.setTimeout(onProfileDownloaded, 3000);
      window.location = `https://web-api.iapps.ir/accounts/deviceInfo/mobileConfig?token=${mobileInfoToken}&source=pwa`;
    }
  };
  render() {
    const { loading } = this.state;

    return (
      <div className="device-registration-step">
        <p className="step">مرحله اول</p>
        <img src="/images/step-1.png" alt="" />
        <p className="step-content">
          پس از این که دکمه «دانلود پروفایل» را زدید گزینه Allow را انتخاب کنید.
        </p>
        <button
          onClick={() => {
            this.downloadProfile();
          }}
        >
          {loading ? (
            <PulseLoader
              css={{ "text-align": "center" }}
              color="#fff"
              size="8"
            />
          ) : (
            "دانلود پروفایل"
          )}
        </button>
      </div>
    );
  }
}

export default DownloadProfile;
