import "whatwg-fetch";
import "core-js/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from '@sentry/browser';

// Sentry.init({dsn: "https://3efe6331e9b64d45a786c18191212ce9@sentry.iapps.ir/2"});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
