import BaseService from './BaseService';

class AppService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  featuredListingApps = (sectionId, page, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/featured/section/${sectionId}/apps/${page}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  featuredApps = (page, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/featured/${page}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  search = (keyword, page, size, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get('/apps/search/', {
      params: {
        q: keyword,
        page,
        size,
      },
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  trendingSearches = (callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get('/apps/search/trends').then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  appsByDeveloper = (username, page, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/app/developer/${username}/list/${page}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  getApp = (appId, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/app/${appId}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };


  getAppWithNumericId = (numericId, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/app/numericId/${numericId}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };


  categoryList = (callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/category/list`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };


  appListByCategory = (externalId, page, callback) => {
    this.httpClient.defaults.headers.common.Authorization = undefined;
    this.httpClient.get(`/apps/app/category/${externalId}/list/${page}`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  requestApp = (appName, appStoreUrl, description, callback) => {
    this.httpClient.post('/apps/appRequests/', {
      appName,
      appStoreUrl,
      description,
    }).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  polls = (callback) => {
    this.httpClient.get(`/apps/polls`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  sendPollAnswer = (pollId, answers, callback) => {
    this.httpClient.put(`/apps/polls/${pollId}/answers`, answers).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

  sendPollClickEvent = (pollId, callback) => {
    this.httpClient.post(`/apps/polls/${pollId}/storeClick`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  }



}

export default AppService;
