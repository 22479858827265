import BaseService from "./BaseService";
import jsCookie from "js-cookie";

class AccountService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  createAccount = (username, password, phoneNumber, callback) => {
    this.httpClient
      .post(
        "/accounts/",
        { username, password, phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  userDetails = (callback) => {
    this.httpClient
      .get("/accounts/current", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  confirmPhoneNumber = (phoneNumber, code, callback) => {
    this.httpClient
      .get(`/accounts/phoneNumberConfirmation/${phoneNumber}/${code}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  resetPassword = (phoneNumber, callback) => {
    this.httpClient
      .get(`/accounts/${phoneNumber}/resetPassword`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  resetPasswordVerfiy = (phoneNumber, password, token, callback) => {
    this.httpClient
      .put(
        `/accounts/${phoneNumber}/resetPassword`,
        { password, token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  phoneNumberConfirmation = (phoneNumber, code, callback) => {
    this.httpClient
      .get(`/accounts/phoneNumberConfirmation/${phoneNumber}/${code}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        this.onError(error, callback);
      });
  };

  requestNewConfirmationCode = (phoneNumber, callback) => {
    this.httpClient
      .post(
        `/accounts/requestPhoneActivationCode/${phoneNumber}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  isDeviceRegistered = (token, callback) => {
    this.httpClient
      .get(`/accounts/deviceInfo/mobileConfig/status?token=${token}`, {
        headers: {
          "X-API-Version": 1,
        },
      })
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  getDeviceInfo = (token, callback) => {
    this.httpClient
      .get(`/accounts/deviceInfo/${token}`, {
        headers: {
          "X-API-Version": 1,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          window.location = "/";
          this.onResponse(response, callback);
        }
      })
      .catch((error) => {
        this.requestMobileConfigInfo({
          success: (res) => {
            jsCookie.set("MOBILE_CONFIG_INFO_TOKEN", res.token, {
              expires: 365,
            });
            this.onResponse(res, callback);
          },
          error: (code) => {},
        });
        this.onError(error, callback);
      });
  };

  requestMobileConfigInfo = (callback) => {
    this.httpClient
      .get(`/accounts/deviceInfo/requestMobileConfigInfo?source=pwa`, {
        headers: {
          "X-API-Version": 1,
        },
      })
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };
}

export default AccountService;
