import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getImageUrlForFileId } from '../../shared/networking/Api';
import Api from '../../shared/networking/Api';

class CategoryListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: true,
      categories: [],
    }
  }

  componentDidMount() {
    this.requestCategories();
  }

  requestCategories = () => {
    Api().appService.categoryList({
      success: (categories) => {
        this.setState({
          categories,
        });
      },
      error: () => {
        // TODO: handle error
      }
    });
  };

  onBackButtonClicked = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.goBack();
  };

  render() {
    const { categories } = this.state;

    return (
      <div className="category-listing">
        <div className="view-navigation-bar">
          <a href="#" onClick={this.onBackButtonClicked}><img src="/images/icon-back.svg" alt="" /></a>
          <h1 className="title">دسته بندی ها</h1>
        </div>
        <div className="items">
          {
            categories && categories.map((li, index) => (
              <Link to={
                {
                  pathname: `category/${li.externalId}`,
                  state: {
                    sectionTitle: li.title,
                  }
                }
              }>
                <div className="category-item" key={index}>
                  <img src={getImageUrlForFileId(li.iconFileId, 60, 60)} alt={li.title} />
                  <p>{li.title}</p>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withRouter(CategoryListing);
