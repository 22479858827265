import React from "react";
import { Link } from "react-router-dom";
import ym from "react-yandex-metrika";
import { getImageUrlForFileId } from "../../../shared/networking/Api";

const Banner = ({ banner }) => (
  <Link
    className="swiper-slide"
    to={`/i/${banner.app.externalNumericId}`}
    onClick={() => {
      ym("reachGoal", "BANNER_CLICK", {
        SectionsUserInteractions: { Banners: banner.title },
      });
    }}
  >
    <div className="banner">
      <p className="label">{banner.label}</p>
      <h2 className="title">{banner.title}</h2>
      <p className="subtitle">{banner.subtitle}</p>
      <img
        src={getImageUrlForFileId(banner.imageFileId, 800, 600)}
        alt={banner.title}
      />
    </div>
  </Link>
);

export default Banner;
