import React, { useEffect, useState } from "react";

import { ProgressBar, Step } from "react-step-progress-bar";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

const SubscriptionLayoutProcess = function ({ children }) {
  const [progress, setProgress] = useState(false);

  const history = useHistory();

  useEffect(() => {
    switch (history.location.pathname) {
      case "/register-device-tutorial":
        setProgress(49);
        break;
      case "/register-device-segue":
        setProgress(100);
        break;
      case "/packages":
        setProgress(1);
        break;
      default:
        setProgress(100);
    }
  }, [history.location.pathname]);

  return (
    <div className="package-layout">
      <div className="step-progress">
        <ProgressBar
          percent={progress}
          filledBackground="linear-gradient(to right, #027aff, #027aff)"
          height={4}
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <p className="step-name">
                خرید اشتراک
                <span className={classNames({ accomplished: progress > 0 })}>
                  1
                </span>
              </p>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <p className="step-name">
                ثبت دستگاه
                <span className={classNames({ accomplished })}>2</span>
              </p>
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <p className="step-name">
                اتمام فرآیند
                <span className={classNames({ accomplished })}>3</span>
              </p>
            )}
          </Step>
        </ProgressBar>
      </div>
      {children}
    </div>
  );
};

export default React.memo(SubscriptionLayoutProcess);
