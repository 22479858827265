import React, { Component } from "react";
import TabBar from "../../shared/components/TabBar";
import { getAuthToken } from "../../shared/AccountManager";
import Login from "./Login";
import ConfirmPhone from "./ConfirmPhone";
import Profile from "./Profile";

class Account extends Component {
  static defaultProps = {
    isOverlay: false,
    didPressCloseButton: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: null,
      error: null,
      showProfile: true,
      didLogin: null,
      codeFromServer: null,
    };
  }

  didSendOtp = (username, code) => {
    this.setState({
      username,
      codeFromServer: code,
    });
  };

  didLogin = () => {
    const { didLogin } = this.props;
    const { username } = this.state;
    const userId = localStorage.getItem("userId");
    const successfulLoginEvent = {
      event: "login",
      user_id: userId,
      userAgent: navigator.userAgent,
      phoneNumber: username,
    };
    window.dataLayer.push(successfulLoginEvent);
    if (didLogin) {
      didLogin();
    } else {
      this.showProfile();
    }
  };

  showProfile = () => {
    if (getAuthToken()) {
      this.setState({
        showProfile: true,
      });
    }
  };

  onLogOut = () => {
    this.setState({
      username: "",
      showProfile: false,
    });
  };

  onChangeNumberClicked = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      username: null,
    });
  };

  render() {
    const { username, showProfile, codeFromServer } = this.state;
    const { isOverlay, didPressCloseButton } = this.props;
    const token = getAuthToken();
    let isLoggedIn = false;
    if (token) {
      isLoggedIn = true;
    }

    return (
      <div className="account">
        {isOverlay && !username ? (
          <a
            href="#"
            className="account-close-button"
            onClick={(e) => {
              e.preventDefault();
              didPressCloseButton();
            }}
          >
            بستن
          </a>
        ) : (
          ""
        )}
        {isLoggedIn ? (
          showProfile ? (
            <Profile onLogOut={this.onLogOut} />
          ) : (
            ""
          )
        ) : username ? (
          <ConfirmPhone
            username={username}
            codeFromServer={codeFromServer}
            didLogin={this.didLogin}
            onChangeNumberClicked={this.onChangeNumberClicked}
          />
        ) : (
          <Login didSendOtp={this.didSendOtp} />
        )}
        {!isOverlay ? <TabBar /> : ""}
      </div>
    );
  }
}

export default Account;
