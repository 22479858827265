import PersianDate from "persian-date";

export function getPersianDateString(date) {
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 1) {
    return "امروز";
  } else if (diffDays === 2) {
    return "دیروز";
  } else if (diffDays <= 7) {
    return "هفته پیش";
  } else {
    return new PersianDate(date).toLocale("fa").format("dddd D MMMM YYYY");
  }
}
