export function getAuthToken() {
  return localStorage.getItem('auth_token');
}

export function getAuthRefreshToken() {
  return localStorage.getItem('auth_refresh_token');
}

export function setAuthToken(token, refreshToken) {
  try {
    localStorage.setItem('auth_token', token);
    localStorage.setItem('auth_refresh_token', refreshToken);
  } catch (e) {
  }
}