import React from 'react';
import TriadAppItem from './TriadAppItem';

const AppWithScreenShotSectionItem = ({app, screenshots, onDownloadButtonClicked, sectionName}) => (
  <div className="app-with-screenshot swiper-slide">
    <TriadAppItem app={app} onDownloadButtonClicked={onDownloadButtonClicked} sectionName={sectionName} />
    <div className="screenshots">
      {screenshots}
    </div>
  </div>
);

export default AppWithScreenShotSectionItem;
