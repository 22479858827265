import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import AppRouter from "./Router";
import Account from "../containers/account/Account";

import { isStandalone } from "../shared/DeviceUtils";
import AddToHomeScreenMessage from "../components/AddToHomeScreenMessage";
import ErrorBoundary from "./ErrorBoundary";
import CampaignDialog from "./CampaignDialog";
import CampaignModalWithGif from "../shared/components/CampaignModalWithGif";

import IOS16DeveloperMode from "../shared/components/IOS16DeveloperMode";
import MiniCampaignPopup from "../shared/components/MiniCampaignPopup";

class AppContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaignSkipped: true,
      showPrivateModeMessage: false,
      skipAddToHomeScreen: localStorage.getItem("is_first_visit") === "false",
    };
  }

  componentDidMount() {
    // if (localStorage.getItem('khalafi_khodro_campaign_passed') !== 'true') {
    // let queries = qs.parse(window.location.search);
    // if (!queries || queries.utm_medium !== 'landing') {
    //   window.setTimeout(this.showCampaignDialog, 10 * 1000);
    // }
    // }

    // Check localstore for older ios versions
    if (typeof localStorage === "object") {
      try {
        localStorage.setItem("ios_old_version_localstorage_check", "1");
      } catch (e) {
        this.setState({
          showPrivateModeMessage: true,
        });
      }
    }
  }

  onAddToHomeScreenSkip = () => {
    this.setState({
      skipAddToHomeScreen: true,
    });
  };

  isGoogleBot = () => {
    return !!navigator.userAgent.match(/Googlebot/i);
  };

  campaignSkipped = () => {
    this.setState({
      campaignSkipped: true,
    });
  };

  showCampaignDialog = () => {
    // try {
    //   localStorage.setItem('khalafi_khodro_campaign_passed', 'true');
    // } catch (e) {
    // }
    // let path = window.location.pathname;
    // if (path.endsWith('20848796') || path.endsWith('161341142') ||  path.endsWith('680419302') ||  path.endsWith('810802321') ||  path.endsWith('218159182') || path.endsWith('682385081') || path.endsWith('589353554') || path.endsWith('57404100') || path.endsWith('906479651') || path.endsWith('513451618') || path.endsWith('77588943')) {
    //   window.setTimeout(this.showCampaignDialog, 10 * 1000);
    //   return
    // }
    // this.setState({
    //   campaignSkipped: false,
    // });
  };

  showAlopeykDialog = () => {
    let path = window.location.pathname;

    console.log(path);
    if (path.endsWith("923988802")) {
      return true;
    }
    return false;
  };

  showMiniCampaign = () => {
    const isSeen = localStorage.getItem("mini-campaign-dialog");
    if (!isSeen) {
      return true;
    }
    return false;
  };

  campaignDialogSubmit = () => {
    window.location =
      "https://iapps.ir/blog/yalda?utm_source=pwa&utm_medium=popup&utm_campaign=iApps_Yalda1400_Login&utm_id=iapps";
  };

  render() {
    const { skipAddToHomeScreen, campaignSkipped, showPrivateModeMessage } =
      this.state;
    const { isLoginRequired, toggleLoginView } = this.props;
    console.log("rendering");
    return (
      <ErrorBoundary>
        <BrowserRouter>
          {isLoginRequired ? (
            <div className="overlay">
              <Account
                isOverlay
                didLogin={() => {
                  toggleLoginView(false);
                }}
                didPressCloseButton={() => {
                  toggleLoginView(false);
                }}
              />
            </div>
          ) : (
            ""
          )}
          <IOS16DeveloperMode />
          {/* <ServerDownModal /> */}

          {!skipAddToHomeScreen &&
          !showPrivateModeMessage &&
          !isStandalone() &&
          localStorage.getItem("is_first_visit") !== "false" &&
          window.location.pathname === "/" &&
          !this.isGoogleBot() ? (
            <div className="overlay">
              <AddToHomeScreenMessage
                onSkipClicked={this.onAddToHomeScreenSkip}
              />
            </div>
          ) : (
            ""
          )}
          {/* <InstagramLiveCampaignModal /> */}
          {/* {this.showAlopeykDialog() ? (
            <AlopeykModal />
          ) : (
            <CampaignModalWithGif />
          )} */}
          {this.showMiniCampaign() ? (
            <MiniCampaignPopup />
          ) : (
            <CampaignModalWithGif />
          )}
          {/* <IOS16DeveloperMode /> */}
          {/* <CampaignModaliOS /> */}
          {/* <AdHocModal /> */}
          {!campaignSkipped ? (
            <div className="campaign-dialog">
              {/*<CampaignDialog onSkip={this.campaignSkipped} campaign /> */}
              <CampaignDialog
                submit={() => {
                  this.campaignSkipped();
                  this.campaignDialogSubmit();
                }}
                onSkip={this.campaignSkipped}
                dialogTitle=""
                dialogText="فالت رو بگیر و جایزه ببر!"
                dialogBttonText="چطوری؟"
                dialogBannerImage="/images/go-to-camp-faal.jpg"
                cancelButtonTitle="انصراف"
              />
            </div>
          ) : (
            ""
          )}
          {showPrivateModeMessage ? (
            <div className="overlay">
              <div className="private-mode-message">
                <p>
                  برای استفاده از آی اپس از حالت Private مرورگر خود خارج شده و
                  دوباره امتحان کنید.
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <CampaignModal /> */}

          <Helmet>
            {window.location.pathname !== "/packages" && (
              <meta name="robots" content="noindex, follow" />
            )}
          </Helmet>

          <AppRouter />
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoginRequired: state.userReducer.isLoginRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
