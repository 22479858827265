import React from "react";
import classNames from "classnames";

// 0 sabz
// 1 abi tire
// 2 abi roshan
// 3 banafsh
// 4 ghermez

const reward = {
  16: {
    logo: "filimo-logo.svg",
    title: "به همراه اشتراک یک ماهه فیلیمو",
  },
  17: {
    logo: "taaghche-logo.svg",
    title: "به همراه اشتراک یک ماهه طاقچه",
  },
};

const SubscriptionPackage = ({
  item,
  index,
  priceAfterVoucherApplied,
  onClick,
}) => {
  let colors = {
    0: ["#A4FFF9", "#1B8AF4"],
    1: ["#E5DCFF", "#1B8AF4"],
    2: ["#B8DCFF", "#1B8AF4"],
  };
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    colors = {
      0: ["#9BF9FF", "#000"],
      1: ["#AA8CFF", "#000"],
      2: ["#3AACFF", "#000"],
    };
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        window.dataLayer.push({ event: "package_clicked", package: item });
        onClick();
      }}
      id={`subscription-package-${item.id}`}
    >
      <div className="subscription-package">
        <div
          className={classNames("content", "package")}
          style={{
            backgroundColor: colors[index][0],
          }}
        >
          <div className="package-header-container">
            <div className="package-header">
              <div className="detail">
                <h3>{item.displayName}</h3>
                <p className="description">{item.description || ""}</p>
                {reward[item.id] && (
                  <span className="reward">
                    {reward[item.id].logo && (
                      <img
                        src={`/images/${reward[item.id].logo}`}
                        alt={reward[item.id].title}
                      />
                    )}
                    <p className="title">{reward[item.id].title}</p>
                  </span>
                )}
              </div>

              {item.originalPrice ? (
                <div className="prices">
                  <p className={classNames("originalPrice", "price")}>
                    {item.originalPrice / 10000}
                  </p>
                  <p className="price" style={{ color: colors[index][1] }}>
                    {item.price / 10000}
                  </p>
                  <p className="currency">هزار تومان</p>
                </div>
              ) : (
                <p
                  className={classNames("price", {
                    hasVoucher: priceAfterVoucherApplied,
                  })}
                >
                  {item.price / 10000} {<span>هزار تومان</span>}
                </p>
              )}
            </div>
          </div>

          {/*{priceAfterVoucherApplied ? <p className="voucherPrice">{priceAfterVoucherApplied / 10} <span>تومان</span></p> : ''}*/}
        </div>
      </div>
    </a>
  );
};
export default SubscriptionPackage;
