import React, { Component } from 'react';

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    // TODO: send error log to metrica
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  showHomeScreen = () => {
    window.location = '/';
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="error-boundary">
          <img src="/images/error-boundary.png" alt="" className="background" />
          <div className="content">
            <h3>خطایی رخ داده است</h3>
            <p>متاسفانه خطلایی در پردازش اپلیکیشن رخ داده است، لطفا مجددا تلاش کنید و در صورت حل نشدن مشکل با پشتیبانی آی اپس تماس بگیرید</p>

            <div className="actions">
              <button onClick={this.showHomeScreen}>صفحه اصلی</button>
              <a href="https://iapps.ir/faq">پشتیبانی</a>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
