import axios from 'axios';
import _ from 'lodash';

import { store } from '../../app/store';

import AuthService from './AuthService';
import AccountService from './AccountService';
import AppService from './AppService';
import ReviewService from './ReviewService';
import DownloadService from './DownloadService';
import AppInstallerService from './AppInstallerService';
import DeveloperService from './DeveloperService';
import NotificationService from './NotificationService';
import SubscriptionService from './SubscriptionService';
import PaymentService from './PaymentService';
import OrdersService from './OrdersService';
import BillingService from './BillingService';
import { getAuthToken } from '../AccountManager';
import { getDeviceInfo } from '../DeviceUtils';

const API_BASE_URL = process.env.REACT_APP_IAPPS_API_BASE_URL;
const BASE_URL = process.env.REACT_APP_IAPPS_BASE_URL;
const API_DOWNLOAD_URL = process.env.REACT_APP_IAPPS_DOWNLOAD_URL;
let deviceModel = 'unknown';
let deviceType = 'unknown';
let hardwareVendor = 'unknown';
let platformName = 'unknown';
let platformVersion = 'unknown';
let browserName = 'unknown';
let browserVersion = 'unknown';

getDeviceInfo((data) => {
  if (data && data.device) {
    deviceType = data.device.devicetype;
    hardwareVendor = data.device.hardwarevendor;
    if (data.device.hardwarename)
      deviceModel = data.device.hardwarename.join(', ');
    platformName = data.device.platformname;
    platformVersion = data.device.platformversion;
    browserName = data.device.browsername;
    browserVersion = data.device.browserversion;
  }
});


function getToken() {
  return getAuthToken();
}

export function authToken() {
  return getToken();
}
export function getImageUrlForFileId(fileId, width, height) {
  return`https://static.iapps.ir/apps/file/image/${fileId}/${width}x${height}.jpg?key=Ndu5E2g4BVBJVAwsDk82jSeThSw6CcKq`;
}

export const manifestUrl = (downloadToken, phoneNumber) => `${API_DOWNLOAD_URL}/installer/${phoneNumber}/${downloadToken}/manifest`;

export function getAppUrl(appId) {
  return`${BASE_URL}/apps/app/${appId}`;
}

export function canonicalUrlForApp(title, externalId) {
  return `https://iapps.ir/app/${title.replace(/[^A-Za-z0-9\u0622\u0627\u0628\u067E\u062A-\u062C\u0686\u062D-\u0632\u0698\u0633-\u063A\u0641\u0642\u06A9\u06AF\u0644-\u0648\u06CC\u06F0-\u06F9\u202C\u064B\u064C\u064E-\u0652]+/gmi, ' ').trim().replace(/\s+/gmi, '-').replace(/[\u202C\u064B\u064C\u064E-\u0652]/, '')
    .toLowerCase()}/${externalId}`;
}

export function onResponse(response, callback) {
  if (response.status >= 200 && response.status < 300) {
    callback.success(response.data);
  } else if (callback.has('error')) {
    callback.error(response.status);
  }
}

export function onError(error, callback) {
  if (error.response) {
    // if (error.response.status === 401) {
    //   store.dispatch(makeAlert('ابتدا وارد شوید!', 'danger'))
    // } else if (error.response.status === 451) {
    //   store.dispatch(makeAlert('تنها از داخل ایران می‌توانید از آی‌اپس استفاده کنید.', 'danger'))
    // } else if(error.response.status === 402) {
    //   callback.success(error.data);
    // }
    if (_.has(callback, 'error')) {
      callback.error(error.response.status, error.response.statusText);
    }
  } else {
    if (_.has(callback, 'error')) {
      callback.error(null, 'No response');
    }
  }
}

export default function (includeAuthHeader = true) {
  const instance = axios.create({
    baseURL: API_BASE_URL,
  });
  instance.defaults.headers.common.Accept = 'application/json';
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  if (getToken()) {
    instance.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
  } else {
    instance.defaults.headers.common.Authorization = undefined;
  }

  instance.defaults.headers.common['X-Device'] = deviceModel;
  instance.defaults.headers.common['X-Device-Type'] = deviceType;
  instance.defaults.headers.common['X-Hardware-Vendor'] = hardwareVendor;
  instance.defaults.headers.common['X-Platform'] = platformName;
  instance.defaults.headers.common['X-Platform-Version'] = platformVersion;
  instance.defaults.headers.common['X-Browser-Name'] = browserName;
  instance.defaults.headers.common['X-Browser-Version'] = browserVersion;

  return {
    authService: new AuthService(instance),
    accountService: new AccountService(instance),
    appService: new AppService(instance),
    reviewService: new ReviewService(instance),
    appInstallerService: new AppInstallerService(instance),
    notificationService: new NotificationService(instance),
    developerService: new DeveloperService(instance),
    subscriptionService: new SubscriptionService(instance),
    paymentService: new PaymentService(instance),
    downloadService: new DownloadService(instance),
    ordersService: new OrdersService(instance),
    billingService: new BillingService(instance),
  };

}
