import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Lottie from "react-lottie-player";
import eid from "../../shared/components/eid";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import api from "../../shared/networking/Api";
import BannerSection from "./components/BannerSection";
import TriadAppsSection from "./components/TriadAppsSection";
import SingleAppSection from "./components/SingleAppSection";
import AppWithScreenShotSection from "./components/AppWithScreenShotSection";
import CategoriesSection from "./components/CategoriesSection";
import TabBar from "../../shared/components/TabBar";
import AppDownloader from "../../shared/components/AppDownloader";
import NetworkErrorMessage from "../../components/NetworkErrorMessage";
import PurchasePackageBottomActionView from "./components/PurchasePackageBottomActionView";

class FeaturedApps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      networkError: false,
      sections: null,
      targetPage: props.targetPage,
      appToDownload: null,
      showSmallTitle: false,
      showPurchaseButton: false,
      showDeveloperModal: false,
    };
  }

  componentDidMount() {
    this.loadSections();

    window.addEventListener("scroll", this.handleScroll);

    api(false).ordersService.userSubscriptionPackages({
      success: () => {
        this.setState({
          showPurchaseButton: false,
        });
      },
      error: (code) => {
        if (code === 404 || code === 401) {
          this.setState({
            showPurchaseButton: true,
          });
        }
      },
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { showSmallTitle } = this.state;

    if (window.scrollY > 100 && !showSmallTitle) {
      this.setState({
        showSmallTitle: true,
      });
    }

    if (window.scrollY <= 100 && showSmallTitle) {
      this.setState({
        showSmallTitle: false,
      });
    }
  };

  loadSections = () => {
    const { targetPage, homeSections, appSections, gameSections, setSections } =
      this.props;

    this.currentTargetPage = targetPage;

    switch (targetPage.toUpperCase()) {
      case "HOME":
        if (homeSections) {
          this.setState({
            sections: homeSections,
            loading: false,
          });
          return;
        }
        break;
      case "APP":
        if (appSections) {
          this.setState({
            sections: appSections,
            loading: false,
          });
          return;
        }
        break;
      case "GAME":
        if (gameSections) {
          this.setState({
            sections: gameSections,
            loading: false,
          });
          return;
        }
        break;
      default:
        break;
    }

    api(false).appService.featuredApps(targetPage, {
      success: (sections) => {
        setSections(targetPage.toUpperCase(), sections);
        this.setState({
          sections,
          loading: false,
        });
      },
      error: () => {
        this.setState({
          networkError: true,
          loading: false,
        });
      },
    });
  };

  onDownloadButtonClicked = (app) => {
    this.setState({
      appToDownload: app,
    });
  };

  onDownloadMenuCanceled = () => {
    this.setState({
      appToDownload: null,
    });
  };

  setShowDeveloperModal = () => {
    this.setState({
      showDeveloperModal: true,
    });
  };

  showSubscriptionPackages = () => {
    const { history } = this.props;

    history.push({
      pathname: "/packages",
      state: { checkUserAuthentication: false },
    });
  };

  downloadRequiresLogin = () => {
    this.setState({
      appToDownload: null,
    });

    const { toggleLoginView } = this.props;

    toggleLoginView(true);
  };

  render() {
    const {
      loading,
      sections,
      appToDownload,
      showSmallTitle,
      networkError,
      showPurchaseButton,
      showDeveloperModal,
    } = this.state;
    const { targetPage } = this.props;

    let developerLocal = localStorage.getItem("app_downloaded");

    if (targetPage !== this.currentTargetPage) {
      this.setState({
        loading: true,
        sections: null,
      });

      this.loadSections();

      window.scrollTo(0, 0);
    }

    const sectionItems = [];
    if (sections) {
      sections.forEach((section) => {
        if (section.sectionType === "BANNER") {
          sectionItems.push(
            <BannerSection section={section} key={section.id} />
          );
        } else if (
          section.sectionType === "LISTING" &&
          (section.appListingSection.type === "TOP_FREE" ||
            section.appListingSection.type === "TOP_PAID" ||
            section.appListingSection.type === "TOP_VIP" ||
            section.appListingSection.type === "LATEST_UPDATES" ||
            section.appListingSection.type === "TRIAD")
        ) {
          sectionItems.push(
            <TriadAppsSection
              section={section}
              key={section.id}
              onDownloadButtonClicked={this.onDownloadButtonClicked}
            />
          );
        } else if (
          section.sectionType === "LISTING" &&
          section.appListingSection.type === "SINGLE"
        ) {
          sectionItems.push(
            <SingleAppSection section={section} key={section.id} />
          );
        } else if (
          section.sectionType === "LISTING" &&
          section.appListingSection.type === "SINGLE_WITH_SCREENSHOT"
        ) {
          sectionItems.push(
            <AppWithScreenShotSection
              section={section}
              key={section.id}
              onDownloadButtonClicked={this.onDownloadButtonClicked}
            />
          );
        } else if (section.sectionType === "CATEGORY_LIST") {
          sectionItems.push(
            <CategoriesSection section={section} key={section.id} />
          );
        }
      });
    }

    if (networkError) {
      return (
        <div>
          <NetworkErrorMessage
            buttonAction={() => {
              this.setState({
                networkError: false,
                loading: true,
              });

              this.loadSections();
            }}
          />
          <TabBar />
        </div>
      );
    }

    return (
      <>
        <div className="featured-apps">
          <Header
            title={
              targetPage === "app"
                ? "برنامه ها"
                : targetPage === "game"
                ? "بازی ها"
                : "ویترین"
            }
            small={showSmallTitle}
          />

          {sectionItems}
          {loading ? <LoadingSpinner /> : ""}
          <div className="downloader-container">
            {appToDownload ? (
              <AppDownloader
                app={appToDownload}
                onCanceled={this.onDownloadMenuCanceled}
                setShowDeveloperModal={this.setShowDeveloperModal}
                onPackageRequired={this.showSubscriptionPackages}
                onLoginRequired={this.downloadRequiresLogin}
              />
            ) : (
              ""
            )}
          </div>
          {showPurchaseButton ? <PurchasePackageBottomActionView /> : ""}
          <TabBar />
        </div>
        {/* {(showDeveloperModal || developerLocal === "1") && <IOS16DeveloperMode />} */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeSections: state.sectionsReducer.homeSections,
    appSections: state.sectionsReducer.appSections,
    gameSections: state.sectionsReducer.gameSections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSections: (targetPage, sections) =>
      dispatch({ type: targetPage, sections }),
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeaturedApps)
);
