import React from 'react';

const Dialog = ({
  title,
  message,
  positiveButtonTitle,
  negativeButtonTitle,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
  hasTwoButton = true
}) => (
  <div className="dialog-container">
    <div className="dialog">
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="message">{message}</p>
      </div>
      <div className="actions">
        <button style={!hasTwoButton ? {width: "100%"} : {}} className="positive" onClick={onPositiveButtonClicked ? onPositiveButtonClicked : () => {}}>{positiveButtonTitle}</button>
        {hasTwoButton && <button className="negative" onClick={onNegativeButtonClicked ? onNegativeButtonClicked : () => {}}>{negativeButtonTitle}</button>}
      </div>
    </div>
  </div>
);

Dialog.defaultProps = {
  positiveButtonTitle: 'تایید',
  negativeButtonTitle: 'انصراف',
};

export default Dialog;
