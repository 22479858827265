import React from "react";
import { PulseLoader } from "react-spinners";
import { withRouter } from "react-router-dom";

import Api from "../../../shared/networking/Api";

const WaitingForRegistration = ({ token, history }) => {
  WaitingForRegistration.registerTimeout(history, token);

  return (
    <div className="device-registration-step">
      <p className="step">مرحله سوم</p>
      <p className="step-content">
        لطفا مراحل نصب پروفایل را از تنظیمات تکمیل کنید و منتظر بمانید تا پیغام
        ثبت موفقیت آمیز را دریافت کنید. این فرایند با توجه به تغییرات جدید اپل
        ممکن است ۲۴ تا ۷۲ ساعت زمان بر باشد که در این صورت پس از ثبت از طریق
        پیامک به شما اطلاع رسانی می شود.
      </p>
      <div className="loading">
        <PulseLoader
          css={{ "text-align": "center" }}
          color="#027aff"
          size="11"
        />
        <p>در انتظار تکمیل مراحل ثبت</p>
        <a
          className="checkToken"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            WaitingForRegistration.checkTone(history, token);
          }}
        >
          مراحل را انجام دادم و دستگاهم با موفقیت ثبت شد
        </a>
      </div>
    </div>
  );
};

WaitingForRegistration.checkTone = (history, token, showErrors = true) => {
  Api().accountService.isDeviceRegistered(token, {
    success: (response) => {
      if (response && response.isDeviceRegistered === "true") {
        try {
          localStorage.setItem("REGISTERED_MOBILE_CONFIG_INFO_TOKEN", token);
        } catch (e) {}
        history.push("/register-device-segue");
      } else {
        WaitingForRegistration.registerTimeout(history, token);
      }
    },
    error: () => {
      WaitingForRegistration.registerTimeout(history, token);
    },
  });
};

WaitingForRegistration.registerTimeout = (history, token) => {
  setTimeout(() => {
    WaitingForRegistration.checkTone(history, token, false);
  }, 2000);
};

export default withRouter(WaitingForRegistration);
