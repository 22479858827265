import React from "react";
import { Switch } from "react-router-dom";

import FeaturedApps from "../containers/featured-apps/FeaturedApps";
import PublicRoute from "./PublicRoute";
import Search from "../containers/search/Search";
import Account from "../containers/account/Account";
import AppDetails from "../containers/app-details/AppDetails";
import SubscriptionPackages from "../containers/subscription-packages/v2/SubscriptionPackagesV2";
import AppListing from "../containers/app-listing/AppListing";
import CategoryListing from "../containers/category-listing/CategoryListing";
import DeviceRegistration from "../shared/components/DeviceRegistration";
import EnterpriseInstallationTutorial from "../containers/enterprise-installation-tutorial/EnterpriseInstallationTutorial";
import DeviceRegistrationWizard from "../containers/device-registration-wizard/DeviceRegistrationWizard";
import SubmitReview from "../containers/submit-review/SubmitReview";
import NotFound from "../components/NotFound";
import Terms from "../containers/terms/Terms";
import RegisterDeviceSegue from "../containers/device-registration-wizard/components/RegisterDeviceSegue";
import Support from "../containers/support/Support";
import MasterkalaCampaign from "../containers/MasterkalaCampaign";

const Router = () => {
  return (
    <Switch>
      <PublicRoute exact path="/" component={FeaturedApps} targetPage="home" />
      <PublicRoute
        exact
        path="/vitrin"
        component={FeaturedApps}
        targetPage="home"
      />
      <PublicRoute
        exact
        path="/apps"
        component={FeaturedApps}
        targetPage="app"
      />
      <PublicRoute
        exact
        path="/games"
        component={FeaturedApps}
        targetPage="game"
      />
      <PublicRoute exact path="/search" component={Search} />
      <PublicRoute exact path="/account" component={Account} />
      <PublicRoute exact path="/i/:appId" component={AppDetails} />
      <PublicRoute exact path="/:type/i/:appId" component={AppDetails} />
      <PublicRoute exact path="/packages" component={SubscriptionPackages} />
      <PublicRoute exact path="/apps/:sectionId" component={AppListing} />
      <PublicRoute exact path="/category" component={CategoryListing} />
      <PublicRoute exact path="/category/:categoryId" component={AppListing} />
      <PublicRoute
        exact
        path="/register-device"
        component={DeviceRegistration}
      />
      <PublicRoute
        exact
        path="/register-device-tutorial"
        component={DeviceRegistrationWizard}
      />
      <PublicRoute exact path="/submitReview/:appId" component={SubmitReview} />
      <PublicRoute
        exact
        path="/developers/:developerId"
        component={AppListing}
        listingType="developerApps"
      />
      <PublicRoute
        exact
        path="/enterprise-installation-tutorial"
        component={EnterpriseInstallationTutorial}
      />

      <PublicRoute
        exact
        path="/register-device-segue"
        component={RegisterDeviceSegue}
      />

      <PublicRoute exact path="/imaster" component={MasterkalaCampaign} />

      <PublicRoute exact path="/support" component={Support} />

      <PublicRoute exact path="/terms" component={Terms} />

      <PublicRoute component={NotFound} />
    </Switch>
  );
};

export default Router;
