import React, { Component } from 'react';

class Terms extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }

  onBackButtonClicked = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.length > 2 ? history.goBack() : history.push('/');
  };

  render() {
    return (
      <div className="terms">
        <div className="view-navigation-bar">
          <a href="#" onClick={this.onBackButtonClicked}><img src="/images/icon-back.svg" alt="" /></a>
        </div>
        <h1>قوانین و شرایط استفاده از آی اپس</h1>
        <div class="term-items">
          <p>
            اپ استور ایرانی آی‌اپس، مرجعی است ویژه کاربران سیستم عامل آی‌او‌اس جهت دریافت نسخه نیتیواپلیکیشن های موبایلی، بازی و محتوای تولید شده توسط توسعه دهندگان حقیقی یا حقوقی و تابع کلیه قوانین و مقررات جمهوری اسلامی ایران میباشد؛ توافق‌نامه زیر در خصوص استفاده مناسب و ایمن از مارکت آی‌اپس بوده و جهت جلوگیری از هرگونه مشکلات احتمالی تدوین شده است.
          </p>
          <p>این توافقنامه تعیین‌کننده تعهدات و تکالیف طرفین، بین شرکت دانش بنیان مهام دانش افزار(آی‌اپس) به عنوان طرف اول و کاربر اپ استور آی‌اپس به عنوان طرف دوم، منعقد می‌شود؛ شرکت دانش بنیان مهام دانش افزار(آی‌اپس) صاحب‌امتیاز  اپلیکیشن آی‌اپس است که از این پس در متن «آی‌اپس» نامیده می‌شود و کاربر اپلیکیشن آی‌اپس «کاربر». ورود کاربران به وب‏‌سایت آی‌اپس هنگام استفاده از پروفایل شخصی، طرح‏‌های تشویقی و سایر خدمات ارائه شده توسط آی‌اپس به معنای آگاه بودن و پذیرفتن قوانین و همچنین نحوه استفاده از سرویس‌‏ها و خدمات آی‌اپس میباشد. کلیه اصول و رویه‏‌های آی‌اپس منطبق با قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک و قانون حمایت از حقوق مصرف‌کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط با کاربر است. تایید این شرایط استفاده به منزله قبول و امضای الکترونیکی توافقنامه است.
          </p>
          <h4>تعاریف:</h4>
          <p><strong>توسعه دهنده:</strong> تولیدکنندگان نرم افزار اعم از اشخاص حقیقی یا حقوقی که با آی‌اپس در راستای ارائه برنامه‌های ایرانی همکاری می‌کنند و برنامه‌های خود را از طریق آی‌اپس ارائه می‌دهند و یا می‌فروشند. اعتبار این اشخاص توسط آی‌اپس بررسی می‌شود و مدارک مورد نیاز از ایشان اخذ می‌گردد.
          </p>
          <p><strong>دوره ماهانه:</strong> منظور از ماه در دوره‌های پرداخت و عضویت، 30 روز است.</p>
          <h4>شروط:</h4>
          <p>
            1. کلیه حقوق مادی و معنوی آی‌اپس و نشانه تجاری آن  متعلق به شرکت دانش بنیان مهام دانش افزار(آی‌اپس) است.
          </p>
          <p>
            2. هرگونه استفاده از نام، مطالبات و مستندات و منابع آی‌اپس بدون مجوز کتبی شرکت، مطابق با قوانین غیرمجاز تلقی  شده و آی‌اپس حق پیگرد قانونی در مراجع ذیصلاح را برای خود محفوظ می‌دارد. مجوز استفاده ازآی‌اپس طبق شرایط استفاده حاضر، تنها برای استفاده یک کاربر حقیقی و به نام آن است.
          </p>
          <p>
            3. هرگونه کپی‌برداری و سعی در نفوذ به داده‌های آی‌اپس، طبق قوانین حاکم بر جمهوری اسلامی ایران غیرمجاز تلقی می‌شود و موجب پیگرد قانونی خواهد شد.
          </p>
          <p>
            4. به منظور فعال سازی امکان نصب اپلیکیشن برای کاربران ios،  نیاز است که دستگاه کاربران در پنل‌های دولوپری ارائه شده از سوی کمپانی اپل ثبت گردد که هزینه دلاری به همراه دارد و ممکن است به دلیل محدودیت‌های اعمالی از سوی اپل، این اکانت‌ها شناسی و مسدود شوند و برای ایجاد دسترسی مجدد برای کاربران نیاز به ثبت و پرداخت هزینه مجدد به اپل میباشد. در صورت مسدود شدن حساب کاربر توسط اپل، تمام اپلیکیشن‌های دانلود شده غیرفعال خواهند شد و آی‌اپس تنها در مواردی مسئول است که کاربر اشتراک فعال داشته باشد. در اینصورت آی‌اپس موظف است بدون دریافت هزینه اقدام به فعال سازی مجدد حساب کاربر نماید و سپس درصورت نیاز لازم است جهت فعالسازی مجدد، اپلیکیشن‌های دانلود شده توسط کاربرحذف و مجددا نصب گردد. چنانچه کاربر اشتراک فعال نداشته باشد و حساب او از سوی اپل مسدود شود لازم است مجددا اشتراک تهیه نماید و آی‌اپس هیچ مسئولیتی در خصوص فعال‌سازی رایگان اپلیکیشن‌هاییکه کاربراز قبل دانلود کرده است را ندارد.
          </p>
          <p>
            5. چنانچه کاربر بعد از اتمام اشتراک بخواهد اپلیکیشن‌هاییکه در دوره فعال بودن اعتبار دانلوده کرده‌ است را آپدیت نمایند، لازم است مجددا اشتراک خود را تمدید کند.
          </p>
          <p>
            6. چنانچه کاربر بعد از اتمام اشتراک بخواهد اپلیکیشن‌ جدیدی را دانلود نمایند لازم است مجددا اشتراک خود را تمدید کند.
          </p>
          <p>
            7. اشتراک آی‌اپس تنها قابل نصب بر روی یک دستگاه میباشد و به هیچ‌عنوان قابل انتقال به موبایل دیگری نمیباشد.
          </p>
          <p>
            8. کاربر حق استفاده از علایم و نشان‌های تجاری ثبت شده و ثبت نشده که در آی‌اپس به نمایش در آمده است را ندارد. این ممنوعیت در مورد تمام اجزای فنی و غیر فنی شبکه آی‌اپس لازم الاجرا است؛ گفتنی است تخطی از این ممنوعیت با عناوینی مثل صدور اجازه یا حق ضمنی و تلویحی، یا عدم انکار پس از قبول و غیره قابل توجیه نیست.
          </p>
          <p>
            9. هرگونه استفاده از خدمات اپلیکشین آی‌اپس که طبق قوانین موضوعه کشور از قبیل قانون تجارت الکترونیک، قانون جرائم رایانه ای و قانون مجازات اسلامی رفتار یا عمل مجرمانه محسوب شود، ممنوع است.
          </p>
          <p>
            10. هرگونه اقدامی که به تشخیص آی‌اپس منجر به صدور دستور برخورد با کاربر توسط مراجع قضایی، انتظامی یا امنیتی شود، باعث توقف ارائه خدمات به کاربر خواهد شد.
          </p>
          <p>
            11. آی‌اپس خود را متعهد می‌داند تمام تلاش و ظرفیت خویش را جهت حفظ امنیت اپلیکیشن و پالایش محتوای منتشر شده در آن  به کار گیرد؛ به همین منظور کلیه گزارش های ارسالی از سوی کاربران در خصوص نقص یا عیب محتوا یا احتمال سو استفاده توسط توسعه دهنگان را بررسی و عنداللزوم نسبت به حذف آن محتوا اقدام می نماید. لیکن با توجه به اینکه محتوای موجود در آی‌اپس توسط توسعه دهندگان تولید و مدیریت می شود لذا  در صورت بروز هرگونه خسارت، مسوولیتی متوجه آی‌اپس نخواهد بود. مسئولیت مربوط به کیفیت، محتوا و شرایط استفاده از برنامه بر عهده توسعه‌دهندگان است.آی‌اپس، تنها ارائه‌دهنده این برنامه‌هاست و مسئولیت پشتیبانی و رفع مشکلات بوجود آمده در این برنامه‌ها بر عهده توسعه‌دهنده آن است.
          </p>
          <p>
            12. در مواردی كه آی‌اپس بنا به عللی از قبیل وجود مشکلات زیرساختی (خارج از كنترل شرکت و نه محدود به اختلال در شبکه مخابراتی، اینترنتی، محدودیت‌های کمپانی اپل و غیره) قادر به ارائه خدمات نباشد، مسئولیتی متوجه آی‌اپس نخواهد بود و کاربر به هیچ عنوان نمی تواند ادعای خسارت کند.
          </p>
          <p>
            13. آی‌اپس می‌تواند هر زمان كه صلاح بداند در شرایط و مقررات آی‌اپس تغییرات لازم را اعمال کند. این تغییرات به نحو مقتضی به اطلاع کاربر می‌رسد و کاربر موظف به رعایت و اجرای آن از زمان اطلاع می‌باشد.
          </p>
          <p>
            14. جهت بهبود کیفیت و امنیت سرویس رسانی آی‌اپس، اطلاعات سخت‌افزار موبایل و نیز سیم کارت کاربر در سیستم ذخیره می‌شود.
          </p>
          <p>
            15. آی‌اپس متعهد به رعایت کلیه قوانین از قبیل فهرست مصادیق محتوای مجرمانه موضوع ماده ۲۱ قانون جرایم رایانه‌ای و قانون تجارت الکترونیکی و سایر قوانین است و بنابراین كلیه موضوعات مشكوك و اطلاعات مربوط به اشخاص مظنون به سوء استفاده از آی‌اپس را به مقامات انتظامی ‌و قضائی اعلام می‌کند تا تحت پیگرد قانونی قرار گیرند.
          </p>
          <p>
            16. نرم‌افزار آی‌اپس با استفاده از مجوزهای لازم برنامه‌نویسی شده است و تمام استانداردهای لازم در پیاده‌سازی این نرم‌افزارها رعایت شده است و هیچ‌گونه آسیب نرم‌افزاری و سخت‌افزاری برای دستگاه‌های ساخته شده توسط کمپانی اپل نخواهند داشت. لذا آی‌اپس هیج‌گونه مسئولیتی در قبال هرگونه خرابی دستگاه کاربران نخواهد داشت.
          </p>
          <p>
            17. استفاده از برنامه‌های موجود در آی‌اپس به منزله پذیرفتن قوانین جمهوری اسلامی ایران است و هرگونه استفاده ناصحیح از نرم‌افزارها بر عهده کاربر است و آی‌اپس هیچگونه مسئولیتی در قبال استفاده غیرقانونی از برنامه‌های موجود در این مارکت نخواهد داشت.
          </p>
          <p>
            18. آی‌اپس می‌تواند در هر زمانی و به هر دلیلی هرگونه برنامه‌ای را از لیست نرم‌افزارهای ارائه شده پاک کند و یا ارائه بروزرسانی را قطع کند. 
          </p>
          <p>
            19. آی‌اپس می‌تواند در هر زمانی خدمات ارائه شده توسط این نرم‌افزار را تغییر دهد و می‌تواند هر زمانی قسمت سرویس‌های ارائه شده را تغییر دهد.
          </p>
          <p>
            20. اپلیکیشن‌های دانلود شده از آی‌اپس، لزوماً بعد از اتمام اشتراک غیرفعال نخواهند شد، مگر آنکه اکانت کاربرتوسط اپل شناسایی و یا اپلیکیشن خاصی به درخواست توسعه‌دهنده و یا یکسری دلایل فنی غیرفعال شود که در اینصورت آی‌اپس هیچ تعهدی د راستای فعالسازی مجدد اشتراک و یا بازگشت وجه ندارد.
          </p>
          <p>
            21. از آنجایی که برنامه‌های آی‌اپس از منابع مختلفی تامین می‌شود، علیرغم تست‌های انجام شده ممکن است برخی از این برنامه‌ها بر روی برخی از دستگاه‌ها و یا نسخه‌های مختلف iOS به درستی کار نکند. آی‌اپس تمام تلاش خود را می‌کند که رخ دادن چنین مواردی را به حداقل برساند. آی‌اپس ضمن اینکه مسئولیتی در خصوص اینگونه موارد قبول نمی‌کند، اما بر اساس گزارش کاربران نسبت به بررسی مشکل‌های گزارش شده و رفع مشکل تا حد امکان اقدام خواهد کرد.
          </p>
          <p>
            22. آی‌اپس هیچگونه مسوولیتی در خصوص خسارات ناشی از استفاده از نرم افزار توسعه دهندگان به کاربران ندارد.
          </p>
          <p>
            23. وقتی برای انجام اموری مانند عضویت در خبرنامه‌ها، نوشتن دیدگاه، عضویت در سایت و استفاده از فرم‌های ارتباطی و خدمات حفظ امنیت از سرویس‌های آی‌اپس استفاده می شود، ممکن است آی‌اپس بخشی از اطلاعات شخصی کاربر را در اختیار داشته باشد. این اطلاعات می‌تواند شامل نام، نشانی ایمیل، آی‌دی دستگاه، آی‌پی، شماره تماس همراه یا اطلاعاتی از این قبیل باشد. اطلاعات مذکور همواره نزد آی‌اپس محفوظ هستند و تحت هیچ شرایطی از عمد در اختیار اشخاص دیگر قرار نخواهد گرفت. همچنین بدیهی است که آی‌اپس تمام سعی‌اش را برای حفظ اطلاعات شخصی کاربران به کار خواهد گرفت اما مسئولیتی در برابر رویدادهای احتمالی از حملات امنیتی به سرورهای آی‌اپس، دست‌برد به پرونده‌های فیزیکی، جنگ، سیل، آتش‌سوزی و موارد دیگری از این دست نخواهد داشت.
          </p>
          <p>
            24. آی‌اپس تابع قوانین نظام حاکم بر جمهوری اسلامی‌ ایران است و خود را نسبت به انجام این قوانین موظف می‌داند. لذا اطلاعات شخصی کاربران، مطابق قانون جرایم رایانه‌ای ج.ا.ا بر اساس دستور مقام قضایی قابل ارایه به مراجع ذیصلاح خواهد بود. همچنین اگر کاربر محتوایی برای آی‌اپس ارسال کند که توهین آمیز، نامناسب یا اعتراض برانگیز باشد یا اینکه اقدامات اخلالگرانه‌ای در سرور سایت انجام دهد، آی‌اپس می تواند از اطلاعات فردی کاربر برای توقف چنین اقداماتی استفاده کند. آی‌اپس اطلاعات مربوط به کاربر را تا زمانی که منطقا برای ارائه خدمات مورد نظر لازم باشد در سیستم خود نگه می‌دارد.
          </p>
          <p>
            25. آی‌اپس در مواردی می تواند امکان لازم جهت گفتگوی آنلاین را فراهم نماید وقتی کاربر جهت ارائه دیدگاه درباره نرم‌افزارها در ‌گفت‌و‌گو‌های آنلاین آی‌اپس شرکت می‌کند و یا در جایی نظری درج می‌کند، باید این موارد را رعایت نماید:

            <div class="inner-items">
              <p>
                دیدگاه‌ها باید با رعایت ادب و اخلاق نوشته شوند. 
              </p>
              <p>اظهارنظر‌ها نباید شامل موردی غیرقانونی باشند. موارد غیرقانونی و محتوای مجرمانه از نظر آی‌اپس شامل دیدگاه‌های آزاردهنده، اخلال‌گرانه، برخورنده، افتراآمیز، توهین‌آمیز، تهدیدآمیز، مخرب، مستهجن، ضد دین، دارای اشارات جنسی یا نژادپرستانه‌اند که در قوانین و مقررات جمهوری اسلامی ایران ذکر شده‌اند.</p>
              <p>کاربران از سنین مختلف با توانایی‌ها و سطح اطلاعات گوناگون در گفت‌وگوها شرکت می‌کنند. پس رعایت احترام دیگر افراد الزامی است. </p>
              <p>ارسال اسپم (هرزنامه) یا موارد غیرمرتبط با موضوع بحث ممنوع است. در ضمن استفاده تبلیغاتی و درج آگهی در گفت‌وگوها ممنوع است.</p>
              <p>نظرات می بایست تا حد ممکن خلاصه و به زبان شیرین پارسی باشد. از نوشتن فینگلیش پرهیز شود و اصطلاحات غیرخوانا به‌ کار برده نشود.</p>
              <p>جعل هویت در گفت‌وگو‌های سایت ممنوع است. کاربر نباید خود را به عنوان شخصی دیگر معرفی کند. اگر قصد ندارد نام کامل درج شود، می تواند تنها از اسم کوچک استفاده کند ولی نام نسبت دادن نام اشخاص دیگر ممنوع است. </p>
              <p>آوردن نشانی‌های اینترنتی متعدد در اظهارنظرها ممنوع است.</p>
              <p>توصیه آی‌اپس این است که برای حفظ امنیت خود از انتشار اطلاعات شخصی‌ در گفت‌وگوها و یا به اشتراک گذاشتن آنها با کاربران دیگر خودداری شود.</p>
            </div>
          </p>  
          <p>26. آی‌اپس ممکن است برای دریافت هزینه‌ از کاربر از درگاه‌های مختلف بانکی استفاده کند. این درگاه‌ها در صورت صلاحدید می‌توانند تغییر یابند. کاربر اعلام و اقرار می نماید مسوولیت استفاده از این درگاه‌ها، روند پرداخت و شرایط استفاده از درگاه‌های بانکی با کاربر بوده و آی‌اپس هیچگونه مسوولیتی در خصوص درگاه های مذکور ندارد. مسئولیت اطمینان از آلوده نبودن وسیله الکترونیک کاربر به ویروس‌های کامپیوتری و کی‌لاگرها، حفظ امنیت اطلاعات کارت بانکی و برخورداری از اتصال اینترنت مناسب برای پرداخت اینترنتی با کاربر است. در صورتی که کاربر وجهی را پرداخت نماید، اما به هر دلیلی در روند پرداخت مشکلی ایجاد شود و کد تایید به آی‌اپس نرسد، مبلغ پرداختی کاربر طی ۷۲ ساعت از طرف بانک مربوطه به کاربر عودت داده می‌شود. در غیر اینصورت می‌تواند با مرکز پشتیبانی درگاه پرداخت مربوطه تماس بگیرد. در صورتیکه مرکز پشتیبانی بانکی که پرداخت از طریق آن انجام شده است مشکل را به آی‌اپس ارجاع دهد، کاربر می تواند اطلاعات دقیق پرداختی، شامل ساعت، تاریخ مبلغ، شماره کارت، کد پیگیری (در صورت وجود) را برای آی‌اپس ارسال کند و آی‌اپس در صورت تایید شدن واریز وجه به حساب، آنرا به حساب سرویس ارایه شده به کاربرمنظور می نماید.</p>
          <p>27. هزینه استفاده از سرویس‌های آی‌اپس توسط تیم اداره‌کننده نرم‌افزار تعیین می‌شود. این قیمت‌ها می‌توانند با اطلاع عمومی در زمان‌های معین تغییر یابند. در صورتی که کاربر از سرویس‌های دوره‌ای استفاده کند، قیمت‌های جدید تا زمان پایان اعتبار دوره، روی حساب کاربری محاسبه نخواهند شد. در طول مدت اعتبار حساب کاربری، روندی برای لغو حساب کاربری و درخواست عودت مبلغ پرداختی در آی‌اپس وجود ندارد.در صورتی که به هر دلیل اعم از مسدودی توسط شرکت اپل یا هر دلیل دیگر ارائه خدمت به کاربران معلق یا مسدود گردد، آی‌اپس هیچگونه تعهدی مبنی بر استرداد وجوه پرداختی نخواهد داشت.</p>
          <p>28. آی‌اپس ممکن است به بعضی از کاربران جدید یا قدیمی‌اش، دوره‌های رایگان استفاده از خدمات هدیه دهد. این حساب‌ها در مدت از پیش اعلام شده اعتبار دارند، اما بدیهی است که کاربر پس از پایان دوره استفاده رایگان، در صورت عدم پرداخت هزینه تمدید، مجاز به استفاده از نرم‌افزار‌ها و بروزرسانی‌ها نخواهد بود.</p>
          <p>29. آی‌اپس می‌تواند راسا نسبت به توقف ارایه خدمت اقدام کند.</p>
          <p>30. آی‌اپس تعهدی بابت پشتیبانی از نسخه‌های آزمایشی سیستم عامل iOS ندارد و ممکن است در این نسخه‌ها عملکرد برنامه دچار مشکل شود.</p>
          <p>32. در صورتی که کاربر به تشخیص آی‌اپس از هریک از تعهدات فوق تخطی نماید آی‌اپس میتواند به صورت یک طرفه عضویت وی را لغو نموده و هیچگونه تعهدی برای استرداد مبلغ پرداختی نخواهد داشت. ضمنا می تواند خسارات معنوی و مادی وارده به خود را مطالبه نماید.</p>
          <p>33. با عضویت در آی اپس به صورت خودکار عضو خبرنامه می شوید. شما می توانید با تماس با پشتیبانی آی اپس عضویت خود را لغو کنید.</p>
        </div>  
      </div>
    );
  }
}

export default Terms;
