import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

const TabBar = (props) => {
  let { path } = props.match;
  if (path === "/") {
    path = "/vitrin";
  }

  path = path.split("/")[1];

  return (
    <div className="tab-bar">
      <Link
        to="/vitrin"
        className={classNames("tab-bar-item", {
          active: path === "vitrin",
        })}
      >
        <img src="/images/tab-bar-home.svg" alt="ویترین" />
        <p>ویترین</p>
      </Link>
      <Link
        to="/apps"
        className={classNames("tab-bar-item", {
          active: path === "apps",
        })}
      >
        <img src="/images/tab-bar-app.svg" alt="ویترین" />
        <p>برنامه ها</p>
      </Link>
      <Link
        to="/games"
        className={classNames("tab-bar-item", {
          active: path === "games",
        })}
      >
        <img src="/images/tab-bar-game.svg" alt="ویترین" />
        <p>بازی ها</p>
      </Link>
      <Link
        to="/search"
        className={classNames("tab-bar-item", {
          active: path === "search",
        })}
      >
        <img src="/images/tab-bar-search.svg" alt="ویترین" />
        <p>جستجو</p>
      </Link>
      <Link
        to="/account"
        className={classNames("tab-bar-item", {
          active: path === "account",
        })}
      >
        <img src="/images/tab-bar-profile.svg" alt="حساب کاربری" />
        <p>حساب کاربری</p>
      </Link>
    </div>
  );
};

export default withRouter(TabBar);
