import BaseService from "./BaseService";

class SubscriptionService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  subscriptionList = (callback) => {
    this.httpClient
      .get("/subscriptionPackages/packages", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.onResponse(response, callback);
        }
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  subscribeUser = (
    id,
    useCredit,
    discountCode,
    source,
    medium,
    campaign,
    referrer,
    callback
  ) => {
    const username = localStorage.getItem("PROFILE_USERNAME");
    let body = { useCredit, source, medium, campaign, referrer };
    if (discountCode) body = { ...body, discountCode };

    this.httpClient
      .post(
        `/subscriptionPackages/packages/subscribeUserOnSubscriptionPackage/${id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.dataLayer.push({
          event: "subscribe_user_success",
          payment: body,
          username,
        });
        callback.success(response);
      })
      .catch((error) => {
        window.dataLayer.push({
          event: "subscribe_user_fail",
          payment: body,
          username,
        });
        if (error && error.response && error.response.status) {
          callback.success(error.response);
        } else {
          this.onError(error, callback);
        }
      });
  };
}

export default SubscriptionService;
