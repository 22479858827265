import React, { useState, useEffect, useCallback } from "react";
import qs from "query-string";
import { PulseLoader } from "react-spinners";
import Lottie from "react-lottie-player";
import { useHistory } from "react-router-dom";
import SubscriptionProcessLayout from "../../subscription-packages/v2/SubscriptionProcessLayout";
import Api from "../../../shared/networking/Api";
import Success from "./success.json";
import Waiting from "./waiting.json";
import Blocked from "./blocked.json";

const RegisterDeviceSegue = function () {
  const history = useHistory();
  const [deviceRegistered, setDeviceRegistered] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { token, conflict } = qs.parse(history.location.search);

  const mobileInfoToken = localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN");

  console.log(deviceRegistered, isFailed, isProcessing);

  const isDeviceRegistered = useCallback(() => {
    Api(false).accountService.isDeviceRegistered(mobileInfoToken, {
      success: (response) => {
        if (response.isDeviceRegistered === "true") {
          setDeviceRegistered(true);
          localStorage.setItem("REGISTERED_MOBILE_CONFIG_INFO_TOKEN", token);
        }
        if (response.isProcessing === "true") {
          setIsProcessing(true);
        }
        if (response.isFailed === "true") {
          setIsFailed(true);
        }
      },
      error: (code) => {
        if (code === 404) history.push("/register-device-tutorial");
      },
    });
  }, [history, mobileInfoToken, token]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(isDeviceRegistered, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isDeviceRegistered]);

  let resultComponent = (
    conflict,
    deviceRegistered,
    isFailed,
    isProcessing
  ) => {
    if (conflict === "true") {
      return (
        <>
          <div className="register-device-segue__title">
            خطایی در ثبت دستگاه شما رخ داده‌است.
          </div>
          <Lottie
            animationData={Blocked}
            play
            loop={false}
            style={{ width: "320px", marginTop: "20px" }}
            speed={0.7}
          />
          <div className="register-device-segue__description">
            شما حداکثر تعداد مجاز برای ثبت دستگاه را استفاده کرده اید، لطفا برای
            ثبت دستگاه جدید مجددا اشتراک تهیه نمایید.
          </div>
          <button onClick={() => history.push("/account")}>متوجه شدم</button>
        </>
      );
    } else if (isFailed) {
      return (
        <>
          <div className="register-device-segue__title">
            خطایی در ثبت دستگاه شما رخ داده‌است.
          </div>
          <Lottie
            animationData={Blocked}
            play
            loop={false}
            style={{ width: "320px", marginTop: "20px" }}
            speed={0.7}
          />
          <div className="register-device-segue__description">
            با توجه به اعمال محدودیت از سمت اپل، دستگاه شما بین ۱۸ تا ۳۳ روز
            قابل ثبت نیست؛ این مدت باید طی شود تا دستگاه شما در سیستم ثبت شود.
            از صبر و شکیبایی شما ممنونیم.
          </div>
          <button onClick={() => history.push("/account")}>متوجه شدم</button>
        </>
      );
    } else if (isProcessing) {
      return (
        <>
          <div className="register-device-segue__title">
            فرآیند ثبت دستگاه شما آغاز شد.
          </div>
          <Lottie
            animationData={Waiting}
            play
            loop={true}
            style={{ width: "320px", marginTop: "20px" }}
            speed={0.7}
          />
          <div className="register-device-segue__description">
            با توجه به تغییرات جدید اپل، تا<span> ۷۲ ساعت</span> زمان نیاز است
            تا اکانت شما فعال شود. بعد از دریافت پیامک دوم می‌توانید
            اپلیکیشن‌های خود را نصب کنید. ممنون از شکیبایی و همراهی شما
          </div>
          <button onClick={() => history.push("/account")}>متوجه شدم</button>
        </>
      );
    } else if (deviceRegistered) {
      return (
        <>
          <div className="register-device-segue__title">
            دستگاه شما با موفقیت ثبت شد.
          </div>
          <Lottie
            animationData={Success}
            play
            loop={false}
            style={{ width: "320px", marginTop: "20px" }}
            speed={0.7}
          />
          <div className="register-device-segue__description">
            دستگاه شما ثبت شد و هم‌اکنون می‌توانید به نصب اپلیکیشن بپردازید.
          </div>
          <button onClick={() => history.push("/account")}>متوجه شدم</button>
        </>
      );
    }
  };

  const isLoading =
    !deviceRegistered && conflict !== "true" && !isFailed && !isProcessing;

  return (
    <SubscriptionProcessLayout>
      <div className="register-segue">
        {isLoading ? (
          <div className="register-segue__loading">
            <PulseLoader color={"#159df6"} loading={true} />
            <h3>در حال ثبت دستگاه</h3>
            <p>لطفا منتظر بمانید</p>
            <button onClick={() => history.push("/support")}>
              تماس با پشتیبانی
            </button>
          </div>
        ) : (
          resultComponent(conflict, deviceRegistered, isFailed, isProcessing)
        )}
      </div>
    </SubscriptionProcessLayout>
  );
};

export default React.memo(RegisterDeviceSegue);
