import React, { Component } from "react";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { toast } from "react-toastify";

import LoadingSpinner from "./LoadingSpinner";
import Api from "../networking/Api";
import InstallKit from "./InstallKit";
import Dialog from "./Dialog";
import IOS16DeveloperMode from "./IOS16DeveloperMode";

const WEB_API_BASE_URL = process.env.REACT_APP_IAPPS_API_BASE_URL;

class AppDownloader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      distributionTypes: null,
      showAllOptions: true,
      adhocExpanded: true,
      enterpriseExpanded: false,
      title: "انتخاب روش دریافت برنامه",
      showKitInstallation: false,
      showWaitDialog: false,
      showDeveloperModal: false,
    };
  }

  componentDidMount() {
    this.getAppInformation();
    disableBodyScroll(document.getElementById("root"));
    window.setTimeout(() => {
      this.setState({
        showDeveloperModal: true,
      });
    }, 1000);
  }

  componentWillUnmount() {
    enableBodyScroll(document.getElementById("root"));
  }

  getAppInformation = () => {
    const { app, onLoginRequired, onPackageRequired } = this.props;
    this.currentApp = app;

    this.setState({ loading: true });
    Api().developerService.getDistributionTypes(app.externalId, {
      success: (response) => {
        this.setState({
          distributionTypes: response,
          loading: false,
        });

        if (
          response &&
          !response.adhocDistribution &&
          response.enterpriseDistribution
        ) {
          this.setState({
            enterpriseExpanded: true,
            adhocExpanded: false,
          });
        }
      },
      error: (code) => {
        // TODO: handle error
        if (code === 401) {
          onLoginRequired();
        }
        if (code === 402) {
          onPackageRequired();
        }
      },
    });
  };

  showAllOptions = () => {
    this.setState({
      showAllOptions: true,
    });
  };

  showAdhoc = () => {
    this.setState({
      adhocExpanded: true,
      enterpriseExpanded: false,
    });
  };

  showEnterprise = () => {
    this.setState({
      adhocExpanded: false,
      enterpriseExpanded: true,
    });
  };

  getTokenAndRedirect = () => {
    const { history, hideEnterprise, app } = this.props;
    const { distributionTypes } = this.state;
    Api().accountService.requestMobileConfigInfo({
      success: (response) => {
        this.setState({
          showAdhocLoading: false,
        });

        if (response.token) {
          try {
            localStorage.setItem("MOBILE_CONFIG_INFO_TOKEN", response.token);
          } catch (e) {}
          history.push("/register-device");
        }
      },
      error: (code) => {
        this.setState({
          showAdhocLoading: false,
        });

        if (code === 402) {
          history.push({
            pathname: "/packages",
            state: {
              checkUserAuthentication: false,
              hideEnterprise: app.externalNumericId === 12207678,
              hideAdhoc:
                app.externalNumericId !== 12207678 ||
                (distributionTypes &&
                  distributionTypes.adhocDistribution === false),
              showCampaignDialog: app.externalNumericId === 12207678,
              redirectedAppId: app.externalNumericId,
            },
          });
        }
      },
    });

    this.setState({
      loading: false,
    });
  };

  checkDownloadStatus = (appId, appVersionId) => {
    const { onCanceled, setShowDeveloperModal } = this.props;
    Api().appInstallerService.checkDownloadRequested(appId, appVersionId, {
      success: (response, status) => {
        if (status === 201) {
          window.setTimeout(() => {
            this.checkDownloadStatus(appId, appVersionId);
          }, 2000);
        } else if (response.token) {
          window.location = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
          this.setState({
            showAdhocLoading: false,
          });
          onCanceled();
        } else if (status === 204) {
          window.setTimeout(() => {
            this.checkDownloadStatus(appId, appVersionId);
          }, 2000);
        }
      },
      error: (code, error) => {
        this.setState({
          showAdhocLoading: false,
        });
        toast.error(`خطا ${code}`);
      },
    });
  };

  onAdhocDownloadButtonClicked = () => {
    // this.props.onCanceled()
    let local = localStorage.getItem("app_downloaded");
    if (!local) {
      localStorage.setItem("app_downloaded", 1);
      this.props.setShowDeveloperModal();
    }
    const { history, app, onCanceled, hideEnterprise } = this.props;
    console.log(app);
    const { distributionTypes } = this.state;

    this.setState({
      showAdhocLoading: true,
    });

    // Check if has token and token is registered
    const mobileInfoToken = localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN");
    const registeredMobileInfoToken = localStorage.getItem(
      "REGISTERED_MOBILE_CONFIG_INFO_TOKEN"
    );

    if (!registeredMobileInfoToken && !mobileInfoToken) {
      this.getTokenAndRedirect();
      return;
    }

    let isRegistered = false;
    let isTokenEmpty = false;
    if (
      !registeredMobileInfoToken ||
      registeredMobileInfoToken !== mobileInfoToken
    ) {
      if (mobileInfoToken) {
        Api(false).accountService.isDeviceRegistered(mobileInfoToken, {
          success: (response) => {
            if (response.isDeviceRegistered === "true") {
              try {
                localStorage.setItem(
                  "REGISTERED_MOBILE_CONFIG_INFO_TOKEN",
                  mobileInfoToken
                );
              } catch (e) {}
              this.onAdhocDownloadButtonClicked();
            } else {
              this.getTokenAndRedirect();
            }
          },
          error: (code) => {
            if (code === 404) {
              isTokenEmpty = true;
            } else {
              // TODO: handle error
            }
          },
        });
      } else {
        isTokenEmpty = true;
      }

      return;
    } else {
      // Token is registered so Download app
      isRegistered = true;
    }
    console.log("clicked");
    if (isTokenEmpty) {
      this.getTokenAndRedirect();
      return;
    }

    Api().appInstallerService.requestDownload(
      app.externalId,
      "adhoc",
      true,
      mobileInfoToken,
      app.appVersion.externalId,
      {
        success: (response, status) => {
          console.log(response, status);
          if (response && response.token) {
            window.location = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
            this.setState({
              showAdhocLoading: false,
            });
            onCanceled();
          } else if (status === 402) {
            history.push({
              pathname: "/packages",
              state: {
                checkUserAuthentication: false,
                hideEnterprise: app.externalNumericId === 12207678,
                hideAdhoc:
                  app.externalNumericId !== 12207678 ||
                  (distributionTypes &&
                    distributionTypes.adhocDistribution === false),
                showCampaignDialog: app.externalNumericId === 12207678,
                redirectedAppId: app.externalNumericId,
              },
            });
          } else if (status === 403) {
            this.getTokenAndRedirect();
          } else if (status === 409 || status === 204 || status === 201) {
            this.checkDownloadStatus(app.externalId, app.appVersion.externalId);
          }
        },
        error: (code, data) => {
          if (code === 402) {
            history.push({
              pathname: "/packages",
              state: {
                checkUserAuthentication: false,
                hideEnterprise: app.externalNumericId === 12207678,
                hideAdhoc:
                  app.externalNumericId !== 12207678 ||
                  (distributionTypes &&
                    distributionTypes.adhocDistribution === false),
                showCampaignDialog: app.externalNumericId === 12207678,
                redirectedAppId: app.externalNumericId,
              },
            });
          } else if (code === 403) {
            if (data.processing) {
              this.setState({
                showWaitDialog: true,
                showAdhocLoading: false,
              });
            }
          }

          // TODO: handle error
        },
      }
    );
  };

  onEnterpriseDownloadButtonClicked = () => {
    const { app, history, hideEnterprise } = this.props;
    const { distributionTypes } = this.state;
    const skipKitInstallation = localStorage.getItem("skip_kit_installation_2");

    this.setState({
      showEnterpriseLoading: true,
    });

    Api().appInstallerService.requestDownload(
      app.externalId,
      "enterprise",
      true,
      null,
      app.appVersion.externalId,
      {
        success: (response) => {
          if (skipKitInstallation !== "true") {
            this.setState({
              showKitInstallation: true,
              title: "نصب عادی برنامه",
            });
            return;
          }
          if (response.token) {
            window.location = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
            this.setState({
              showEnterpriseLoading: false,
            });
          } else if (response.status === 402) {
            history.push("/packages/enterprise");
          }

          this.setState({
            showEnterpriseLoading: false,
          });
        },
        error: (code) => {
          if (code === 402) {
            history.push({
              pathname: "/packages",
              state: {
                checkUserAuthentication: false,
                hideEnterprise:
                  app.externalNumericId === 12207678 &&
                  distributionTypes &&
                  distributionTypes.enterpriseDistribution === false,
                hideAdhoc: true,
                showCampaignDialog: false,
                redirectedAppId: app.externalNumericId,
              },
            });
          }
          // TODO: handle error
          this.setState({
            showEnterpriseLoading: false,
          });
        },
      }
    );
  };

  render() {
    const { app, onCanceled, hideEnterprise } = this.props;
    const {
      loading,
      distributionTypes,
      showAllOptions,
      enterpriseExpanded,
      adhocExpanded,
      title,
      showKitInstallation,
      showEnterpriseLoading,
      showAdhocLoading,
    } = this.state;

    if (this.currentApp && this.currentApp.externalId !== app.externalId) {
      this.getAppInformation();
    }

    // TODO: change icon
    const adhocOption = (
      <div
        className={classNames("distribution-type", {
          disabled: distributionTypes && !distributionTypes.adhocDistribution,
        })}
      >
        <a
          href="#toggle"
          onClick={(e) => {
            e.preventDefault();
            if (distributionTypes.adhocDistribution) this.showAdhoc();
          }}
        >
          <div className="expandable">
            <div className="info">
              <div className="icon">
                <img src="/images/icon-appstore.svg" alt="" />
              </div>
              <div className="description">
                <h1>نسخه ادهاک</h1>
                <p>نصب نسخه Adhoc</p>
              </div>
            </div>
            {showAdhocLoading ? (
              <BeatLoader
                color="#027aff"
                size={7}
                css={{ "margin-top": "5px" }}
              />
            ) : (
              <button
                className="download-button"
                onClick={() => {
                  if (distributionTypes.adhocDistribution) {
                    this.onAdhocDownloadButtonClicked();
                  }
                }}
              >
                <img
                  src="/images/iconfinder-download-2-172461%20(1).svg"
                  alt=""
                />
              </button>
            )}
          </div>
        </a>
        <div
          className={classNames("expandable-content", {
            expanded: adhocExpanded,
          })}
        >
          <div className="content">
            <p>هزینه پرداختی، صرف خرید اکانت از اپل و نگهداری سرویس می شود.</p>
            <div className="features">
              <div className="info">
                <p className="key">برنامه های قابل نصب</p>
                <p className="value">همه</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const enterpriseOption = (
      <div
        className={classNames("distribution-type", {
          disabled:
            distributionTypes && !distributionTypes.enterpriseDistribution,
        })}
      >
        <a
          href="#toggle"
          onClick={(e) => {
            e.preventDefault();
            if (distributionTypes.enterpriseDistribution) this.showEnterprise();
          }}
        >
          <div className="expandable">
            <div className="info">
              <div className="icon">
                <img src="/images/icon-appstore.svg" alt="" />
              </div>
              <div className="description">
                <h1>نسخه اینترپرایز</h1>
                <p>نصب نسخه اینترپرایز (Enterprise)</p>
              </div>
            </div>
            {showEnterpriseLoading ? (
              <BeatLoader
                color="#027aff"
                size={7}
                css={{ "margin-top": "5px" }}
              />
            ) : (
              <button
                className="download-button"
                onClick={this.onEnterpriseDownloadButtonClicked}
              >
                <img
                  src="/images/iconfinder-download-2-172461%20(1).svg"
                  alt=""
                />
              </button>
            )}
          </div>
        </a>
        <div
          className={classNames("expandable-content", {
            expanded: enterpriseExpanded,
          })}
        >
          <div className="content">
            <p>
              <Link to="/enterprise-installation-tutorial">
                آموزش نصب اینترپرایز
              </Link>
            </p>
            <p>
              اپلیکیشن های درخواستی با استفاده از پروفایل Enterprise ارائه می
              گردد
            </p>
            <div className="features">
              <div className="info">
                <p className="key">برنامه های قابل نصب</p>
                <p className="value">همه</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (showKitInstallation) {
      return (
        <div className="app-downloader">
          <div className="actions">
            <h4>{title}</h4>
            <a
              href="#cancel"
              className="cancel-button"
              onClick={(e) => {
                e.preventDefault();
                onCanceled();
              }}
            >
              انصراف
            </a>
          </div>
          <hr className="separator" />
          <InstallKit app={app} />
        </div>
      );
    }

    return (
      <div className="app-downloader">
        <div className="actions">
          <h4>{title}</h4>
          <a
            href="#cancel"
            className="cancel-button"
            onClick={(e) => {
              e.preventDefault();
              onCanceled();
            }}
          >
            انصراف
          </a>
        </div>
        <hr className="separator" />
        {loading ? <LoadingSpinner absolute /> : ""}
        {distributionTypes && distributionTypes.adhocDistribution
          ? adhocOption
          : ""}
        {distributionTypes &&
        distributionTypes.adhocDistribution &&
        distributionTypes.enterpriseDistribution ? (
          <hr className="separator" />
        ) : (
          ""
        )}
        {/* { showAllOptions ? (distributionTypes ? (distributionTypes.enterpriseDistribution ? enterpriseOption: '') : '') : ''} */}
        {/* { distributionTypes ? <hr className="separator" /> : '' } */}
        {distributionTypes && !distributionTypes.adhocDistribution
          ? adhocOption
          : ""}
        {!showAllOptions &&
        !loading &&
        distributionTypes &&
        distributionTypes.enterpriseDistribution ? (
          <a
            href="#AllOptions"
            className="all-options"
            onClick={this.showAllOptions}
          >
            گزینه های بیشتر
          </a>
        ) : (
          ""
        )}
        {this.state.showWaitDialog && (
          <Dialog
            message="دستگاه شما ثبت شده است؛ لطفا جهت دانلود اپلیکیشن‌های خود منتظر دریافت پیامک فعال‌سازی اکانت باشید.        "
            positiveButtonTitle="بستن"
            hasTwoButton={false}
            onPositiveButtonClicked={() => {
              this.setState({ showWaitDialog: false });
            }}
          />
        )}
        {this.state.showDeveloperModal && <IOS16DeveloperMode />}
      </div>
    );
  }
}

export default withRouter(AppDownloader);
