import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../../shared/networking/Api";

const OpenSettings = ({ onOpenSettings }) => {
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isFocused, setIsFocused] = useState(true);

  const mobileInfoToken = localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN");

  const isDeviceRegistered = useCallback(() => {
    Api(false).accountService.isDeviceRegistered(mobileInfoToken, {
      success: (response) => {
        if (
          response.isDeviceRegistered === "false" &&
          response.isProcessing === "false" &&
          response.isFailed === "false"
        ) {
          setIsButtonDisabled(true);
          console.log(isButtonDisabled);
        } else {
          setIsButtonDisabled(false);
        }
      },
      error: (code) => {
        if (code === 404) history.push("/register-device-tutorial");
      },
    });
  }, [history, isButtonDisabled, mobileInfoToken]);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    let interval = null;
    if (isFocused) {
      interval = setInterval(isDeviceRegistered, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isDeviceRegistered, isFocused]);

  useEffect(() => {
    if (isFocused) isDeviceRegistered();
  }, [isDeviceRegistered, isFocused]);

  return (
    <div className="device-registration-step">
      <p className="step">مرحله دوم</p>
      <img src="/images/iapps-profile-install02.gif" alt="آموزش ثبت دستگاه" />
      <ol type="1" className="step-content">
        <li>به تنظیمات دستگاه خود بروید</li>
        <li>گزینه General را انتخاب کنید</li>
        <li>گزینه VPN & Device Management را انتخاب کرده</li>
        <li>سپس بر روی گزینه «نسخه ad-hoc آی‌اپس» بزنید </li>
        <li>گزینه Install را انتخاب کرده و رمز گوشی خود را وارد کنید.</li>
      </ol>
      <button
        disabled={isButtonDisabled}
        onClick={() => {
          onOpenSettings();
        }}
      >
        به تنظیمات رفتم و مراحل را انجام دادم.
      </button>
    </div>
  );
};

export default OpenSettings;
