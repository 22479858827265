import React, { Component } from 'react';
import TriadAppItem from './TriadAppItem';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import { isMobile } from '../../../shared/DeviceUtils';

class DeveloperAppsSection extends Component {

  componentDidMount() {
    let options = {};
    if (isMobile()) {
      options = {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 13,
      };
    } else {
      options = {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      };
    }
    const mySwiper = new Swiper('.triad-swiper-container', options);
  }

  render() {
    const { apps, onDownloadButtonClicked, developerExternalId, developerDisplayName } = this.props;
    const appItems = [];
    const appsCount = apps.length;
    let appGroup = [];
    apps.forEach((app, index) => {
      if (appGroup.length < 2) {
        appGroup.push(<TriadAppItem classNames="developer-app" bordered={true} key={app.externalId} app={app} onDownloadButtonClicked={onDownloadButtonClicked} sectionName="DeveloperApps" />);
      } else {
        appItems.push(<div key={app.externalId + index} className="app-group swiper-slide">{appGroup}</div>);
        appGroup = [];
        appGroup.push(<TriadAppItem classNames="developer-app" bordered={true} key={app.externalId} app={app} onDownloadButtonClicked={onDownloadButtonClicked} sectionName="DeveloperApps" />);
      }

      if (index === appsCount - 1) {
        if (appsCount % 2 !== 0 || appsCount === 2) {
          appItems.push(<div key={app.externalId} className="app-group swiper-slide">{appGroup}</div>);
        }
      }
    });

    return (
      <div className="triad-section developer-apps-section">
        <div className="section-header">
          <h1>سایر اپلیکیشن های توسعه دهنده</h1>
          <Link to={
            {
              pathname: `/developers/${developerExternalId}`,
              state: {
                sectionTitle: developerDisplayName,
              },
            }
          }>بیشتر</Link>
        </div>
        <div className="triad-swiper-container">
          <div className="swiper-wrapper">
            { appItems }
          </div>
        </div>

        <hr className="separator" />
      </div>
    );
  }
}

export default DeveloperAppsSection;
