import React from "react";
import { useHistory } from "react-router-dom";

const SubscriptionPackage = ({
  subscriptionPackage,
  index,
  hidePrice = false,
  processing = false,
  registered = false,
  blocked = false,
  remainingDays = 0,
  notRegistered = false,
}) => {
  const history = useHistory();
  const onPackageClickHandler = () => {
    if (notRegistered) history.push("/packages");
  };
  return (
    <div className="active-package" onClick={onPackageClickHandler}>
      <div className="details">
        <h3 className="title">{subscriptionPackage.displayName}</h3>

        <span className="description">
          {processing && <p>اشتراک شما در حال پردازش است.</p>}
          {blocked && <p>اشتراک شما تا 18 روز دیگر فعال می‌شود.</p>}
          {registered && !processing && (
            <p>مانده اعتبار شما: {remainingDays} روز</p>
          )}
          {notRegistered && (
            <p>دستگاه شما ثبت نشده‌است؛ لطفا به ثبت دستگاه اقدام کنید.</p>
          )}
        </span>
      </div>
    </div>
  );
};

export default SubscriptionPackage;
