import React from 'react';
import { Link } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { getImageUrlForFileId } from '../../../shared/networking/Api';

const SingleAppSection = ({ section }) => {

  const appItems = [];
  if (section.appListingSection && section.appListingSection.type === 'SINGLE') {
    section.appListingSection.apps.forEach((app) => {
      appItems.push(<div className="single-app" key={app.externalId}>
        <Link key={app.externalId} to={`/i/${app.externalNumericId}`} 
        onClick={() => {ym('reachGoal', 'SINGLE_APP_CLICK', {
          SectionsUserInteractions: {[section.title]: app.appDetail.title}
        })}}
        >
          <img src={getImageUrlForFileId(app.appDetail.iconFileId, 200, 200)} alt={app.appDetail.title} />
          <div className="info">
            <p className="title">{app.appDetail.title}</p>
            <p className="category">{app.category.title}</p>
          </div>
        </Link>
      </div>);
    });
  }

  return (
    <div className="single-app-section">
      <div className="section-header">
        <h1>{section.title}</h1>
        <Link to={
          {
            pathname: `/apps/${section.id}`,
            state: {
              sectionTitle: section.title,
            }
          }}>بیشتر</Link>
      </div>
      <div className="scroller">
        {appItems}
      </div>
      <hr className="separator" />
    </div>
  );
};

export default SingleAppSection;
