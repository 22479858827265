import React from 'react';

const NotFound = () => (
  <div className="not-found">
    <img src="/images/error-not-found.png" alt="" className="background" />
    <div className="content">
      <h3>صفحه مورد نظر یافت نشد</h3>
      <p>لطفا از درستی آدرس وارد شده اطمینان حاصل کنید و مجددا تلاش کنید</p>
      <div className="actions">
        <a href="/">صفحه اصلی</a>
        <a href="https://iapps.ir/faq" className="support">پشتیبانی</a>
      </div>
    </div>
  </div>
);

export default NotFound;
