import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Api from '../../../shared/networking/Api';

class RequestApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  submit = (e) => {
    const { onCancel } = this.props;
    const { appName, appStoreUrl, description } = this.state;

    if (e) {
      e.preventDefault();
    }

    this.setState({
      loading: true,
    });

    Api().appService.requestApp(appName, appStoreUrl, description, {
      success: () => {
        toast.success('درخواست شما با موفقیت ثبت شد. پس از انتشار اپلیکیشن از طریق پیامک به شما اطلاع رسانی خواهد شد.');
        onCancel();
      },
      error: () => {
        toast.error('خطا در ثبت درخواست لطفا مجددا تلاش کنید');
        this.setState({
          loading: null,
        });
      },
    });
  };

  render() {
    const { appName, appStoreUrl, description, loading } = this.state;

    return (
      <div className="request-app-container">
        <div className="request-app">
          <a href="#" onClick={e => {e.preventDefault(); e.stopPropagation();}}>
            <div className="app-request-form">
              <form onSubmit={this.submit}>
                <div className="form-group">
                  <label htmlFor="appName">نام اپلیکیشن</label>
                  <input name="appName" id="appName" type="text" onChange={e => this.setState({appName: e.target.value})} value={appName} />
                </div>
                <div className="form-group">
                  <label htmlFor="appStoreLink">لینک اپ استور</label>
                  <input name="appStoreLink" id="appStoreLink" type="text" placeholder="(اختیاری)" onChange={e => this.setState({appStoreUrl: e.target.value})} value={appStoreUrl} />
                </div>
                <div className="form-group">
                  <label htmlFor="description">توضیحات</label>
                  <textarea name="description" id="description" placeholder="(اختیاری)" onChange={e => this.setState({description: e.target.value})} value={description} />
                </div>
                <button className="button" type="submit" onClick={this.submit}>{loading ? <PulseLoader css={{'text-align': 'center'}} color="#fff" size={5} /> : 'ارسال درخواست'}</button>
              </form>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default RequestApp;
