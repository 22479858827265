import React from "react";
import { getImageUrlForFileId } from "../../../shared/networking/Api";
import DownloadButton from "../../../shared/components/DownloadButton";
import { Link } from "react-router-dom";
import AppDownloaderV2 from "../../../shared/components/AppDownloaderV2";

const AppListingItem = ({ app, onDownloadButtonClicked }) => (
  <Link to={`/i/${app.externalNumericId}`}>
    <div className="app-listing-item">
      <img
        src={getImageUrlForFileId(app.appDetail.iconFileId, 180, 180)}
        alt=""
      />
      <div className="info">
        <p className="title">{app.appDetail.title}</p>
        <p className="category">{app.category.title}</p>

        <AppDownloaderV2 app={app} lightVariant />

        {/* <DownloadButton app={app} bordered={false} action={onDownloadButtonClicked} /> */}
      </div>
    </div>
  </Link>
);

export default AppListingItem;
