import React, { Component } from "react";
import Lottie from "react-lottie-player";
import { withRouter } from "react-router-dom";
import Api from "../networking/Api";

class RegisterModal extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			close: false,
			loading: false,
		};
	}

	closeModal = (e) => {
        this.props.setShow()
		// if (!e) {
		// 	this.setState({ close: true });
		// 	return;
		// }

		// if (
		// 	e &&
		// 	e.target &&
		// 	(e.target.id === "modal-with-gif-container" ||
		// 		e.target.id === "modal-with-gif" ||
		// 		e.target.id === "closeButton")
		// ) {
		// 	this.setState({ close: true });
		// }
	};

	// componentDidMount() {
	// 	Api().authService.currentAccount({
	// 		success: () => {
	// 			Api().ordersService.userSubscriptionPackages(
	// 				{
	// 					success: () => {
	// 						console.log("dont show dialog");
	// 					},
	// 					error: () => {
	// 						this.setState({ loading: false });
	// 					},
	// 				},
	// 				true
	// 			);
	// 		},
	// 		error: () => {
	// 			this.setState({ loading: false });
	// 		},
	// 	});
	// }

	showSubscriptionPackages = () => {
		const { history } = this.props;
		this.closeModal();
		history.push({
			pathname: "/packages",
			state: {
				redirectedToPurchaseItemId: 6,
				checkUserAuthentication: true,
			},
		});
	};

	render() {
		// const { close, loading } = this.state;
		// if (close || loading) {
		// 	return "";
		// }

		return (
			<div
				id="modal-with-gif-container"
				class="modal-with-gif-container"
				onClick={this.closeModal}
			>
				<div className="modal-with-gif" id="modal-with-gif" style={{height: "250px"}}>
					<div className="header" style={{justifyContent: "flex-end"}}>
						{/* <img
							src="/images/iapps-logo-typography-blue.png"
							alt="iApps logo"
						/> */}
						<a
							id="closeButton"
							href="#closeButton"
							onClick={(e) => {
								e.preventDefault();
								this.closeModal();
							}}
						>
							<img
								id="close-button-image"
								src="/images/campagin-modal-close-icon.png"
								alt="close button"
							/>
						</a>
					</div>
					<div className="content" style={{
                            marginInline: "34px"
                        }}>
                        <h4 style={{color: "black",textAlign: "center"}}>
                        با توجه به تغییرات جدید اپل،
                        </h4>
                        <h4 style={{color: "black",textAlign: "center"}}>
                         ثبت دستگاه و فعالسازی اکانت
                        </h4>
                        <h4 style={{color: "black",textAlign: "center"}}>
                          حداکثر تا ۷۲ ساعت زمان می‌برد.
                        </h4>
                        <h4 style={{color: "black",textAlign: "center", 
                            opacity: ".7",
                            marginTop: "23px"}}>با تشکر از صبر و شکیبایی شما</h4>
						{/* <div className="main-content">
							<h1>همه برنامه‌هاتو سازگار با iOS 16 داشته باش</h1>
							<button
								onClick={(e) => {
									e.preventDefault();
									this.closeModal();
								}}
							>
								بستن
							</button>
						</div> */}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(RegisterModal);
