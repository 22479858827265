import BaseService from "./BaseService";
import qs from "query-string";

class PaymentService extends BaseService {
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  requestPayment = (
    orderId,
    amount,
    extraCallbackParam,
    preferredGateway,
    callback
  ) => {
    let body = new FormData();
    body.append("orderId", orderId);
    body.append("amount", amount);
    body.append("extraCallbackParam", extraCallbackParam);
    if (preferredGateway) {
      body.append("preferredGateway", preferredGateway);
    }

    this.httpClient
      .post("/payments/requestPayment", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };

  startPayment = (token, callback) => {
    this.httpClient
      .get(`/payments/startPayment/${token}`)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
        }
        this.onResponse(response, callback);
      })
      .catch((error) => {
        this.onError(error, callback);
      });
  };
}

export default PaymentService;
