import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import LoadingSpinner from "../../shared/components/LoadingSpinner";
import Api from "../../shared/networking/Api";
import AppListingItem from "./components/AppListingItem";
import AppDownloader from "../../shared/components/AppDownloader";
import NetworkErrorMessage from "../../components/NetworkErrorMessage";

class AppListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: true,
      apps: [],
      appToDownload: null,
      networkError: false,
    };
  }

  componentDidMount() {
    this.requestData();
  }

  requestData = () => {
    const { params } = this.props.match;
    const { listingType } = this.props;

    if (listingType === "developerApps") {
      this.requestDeveloperApps(0);
      // this.requestDeveloperInfo();
    } else if (!params.categoryId) {
      this.requestFeaturedApps(0);
    } else {
      this.requestCategoryApps(0);
    }
  };

  // requestDeveloperInfo = () => {
  //   const { params } = this.props.match;
  //   Api().developerService.getDeveloperPublicInfo(params.developerId, {
  //     success: (developerInfo) => {
  //       console.log(developerInfo);
  //     },
  //     error: () => {
  //       // TODO: handle error
  //     },
  //   });
  // };

  requestDeveloperApps = (p) => {
    const { params } = this.props.match;
    Api().appService.appsByDeveloper(params.developerId, p, {
      success: (apps) => {
        if (apps.length === 0) {
          this.setState({
            hasMore: false,
          });
        }

        this.setState({
          apps: this.state.apps.concat(apps),
        });
      },
      error: (code) => {
        this.setState({
          networkError: true,
        });
      },
    });
  };

  requestFeaturedApps = (p) => {
    const { params } = this.props.match;
    if (params.sectionId) {
      Api().appService.featuredListingApps(params.sectionId, p, {
        success: (section) => {
          if (section.length === 0) {
            this.setState({
              hasMore: false,
            });
          }

          this.setState({
            apps: this.state.apps.concat(section.content),
          });
        },
        error: (code) => {
          this.setState({
            networkError: true,
          });
        },
      });
    }
  };

  requestCategoryApps = (p) => {
    const { params } = this.props.match;
    Api().appService.appListByCategory(params.categoryId, p, {
      success: (apps) => {
        this.setState({
          apps: !this.state.apps.includes(apps)
            ? this.state.apps.concat(apps)
            : this.state.apps,
        });
      },
      error: () => {
        this.setState({
          networkError: true,
        });
      },
    });
  };

  onBackButtonClicked = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.goBack();
  };

  onDownloadMenuCanceled = () => {
    this.setState({
      appToDownload: null,
    });
  };

  showSubscriptionPackages = () => {
    const { history } = this.props;

    history.push({
      pathname: "/packages",
      state: { checkUserAuthentication: false },
    });
  };

  downloadRequiresLogin = () => {
    this.setState({
      appToDownload: null,
    });

    const { toggleLoginView } = this.props;

    toggleLoginView(true);
  };

  render() {
    const { apps, appToDownload, networkError } = this.state;
    const { state } = this.props.location;
    const { params } = this.props.match;
    const { listingType } = this.props;

    if (networkError) {
      return (
        <div className="app-listing">
          <div className="header">
            <a href="#" onClick={this.onBackButtonClicked}>
              <img src="/images/icon-back.svg" alt="" />
            </a>
            <h1 className="title">{state ? state.sectionTitle : ""}</h1>
          </div>
          <NetworkErrorMessage
            buttonAction={() => {
              this.requestData();
              this.setState({
                networkError: false,
              });
            }}
          />
        </div>
      );
    }

    let loadMoreFunc = this.requestFeaturedApps;
    if (params.categoryId) {
      loadMoreFunc = this.requestCategoryApps;
    } else if (listingType === "developerApps") {
      loadMoreFunc = this.requestDeveloperApps;
    }

    if (apps.length === 0) {
      return (
        <div className="app-listing">
          <div className="header">
            <a href="#" onClick={this.onBackButtonClicked}>
              <img src="/images/icon-back.svg" alt="" />
            </a>
            <h1 className="title">{state ? state.sectionTitle : ""}</h1>
          </div>
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <div className="app-listing">
        <div className="header">
          <a href="#" onClick={this.onBackButtonClicked}>
            <img src="/images/icon-back.svg" alt="" />
          </a>
          <h1 className="title">{state ? state.sectionTitle : ""}</h1>
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreFunc}
          hasMore={this.state.hasMore}
          className="items"
        >
          {apps &&
            apps.map((app, index) => (
              <div className="app-detail" key={app.externalId}>
                <AppListingItem
                  app={app}
                  key={index}
                  onDownloadButtonClicked={() => {
                    this.setState({ appToDownload: app });
                  }}
                />
              </div>
            ))}
        </InfiniteScroll>
        <div className="downloader-container">
          {appToDownload ? (
            <AppDownloader
              app={appToDownload}
              onCanceled={this.onDownloadMenuCanceled}
              onPackageRequired={this.showSubscriptionPackages}
              onLoginRequired={this.downloadRequiresLogin}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(AppListing));
