import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class DeviceRegistration extends Component {
  requestToken = () => {
    const { history } = this.props;

    history.push("/register-device-tutorial");
  };

  render() {
    const { history } = this.props;

    // TODO: contact support link

    return (
      <div className="device-registration">
        <a
          href="#"
          className="back-button"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {" "}
        </a>
        <div className="content">
          <img src="/images/illustration2.png" alt="" />
          <h1>ثبت دستگاه</h1>
          <p>
            برای نصب و استفاده از نسخه پایدار اپلیکیشن های ارائه شده در آی اپس ،
            نیاز به ثبت دستگاه دارید.
          </p>
          <button onClick={this.requestToken}>شروع فرایند ثبت دستگاه</button>
          <a href="https://iapps.ir/faq">ارتباط با پشتیبانی</a>
        </div>
      </div>
    );
  }
}

export default withRouter(DeviceRegistration);
