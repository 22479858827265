import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({component: Component, restricted, isUserLoggedIn, ...rest}) => {
    return (
        <Route {...rest} render={props => {
          return(
            restricted
              ? <Redirect to="/restricted" />
              : <Component {...props} {...rest}/>
        )}} />
    );
};

export default PublicRoute;
