import React, { Component } from "react";
import Ratings from "react-ratings-declarative";
import classNames from "classnames";
import { Line as LineProgressBar } from "rc-progress";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Platform from "platform";
import qs from "query-string";
import { Helmet } from "react-helmet";
import md from "markdown-it";
import ReactEscapeHtml from "react-escape-html";

import Api, {
  getImageUrlForFileId,
  canonicalUrlForApp,
} from "../../shared/networking/Api";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { getPersianDateString } from "../../shared/DateUtils";
import AppDownloader from "../../shared/components/AppDownloader";
import NetworkErrorMessage from "../../components/NetworkErrorMessage";
import DeveloperAppsSection from "../featured-apps/components/DeveloperAppsSection";
import { isStandalone } from "../../shared/DeviceUtils";

import CampaignDialog from "../../app/CampaignDialog";
import AppDownloaderV2 from "../../shared/components/AppDownloaderV2";
import IOS16DeveloperMode from "../../shared/components/IOS16DeveloperMode";

class AppDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: null,
      app: null,
      appRate: null,
      isLandscape: false,
      showFullDescription: false,
      showFullChangelog: false,
      developer: null,
      comments: null,
      title: "",
      showAppLogoInNavBar: false,
      showAppDownloadMenu: false,
      networkError: false,
      developerApps: null,
      notFoundError: false,
      campaignSkipped: true,
      showMiniCampaignModal: true,
      showAppDeveloperModal: false,
      viewCounted: false,
    };
  }

  componentDidMount() {
    this.loadApp();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  loadApp = () => {
    this.getAppId();
    this.getApp();
  };

  handleScroll = () => {
    const { showAppLogoInNavBar } = this.state;
    if (window.scrollY > 118 && !showAppLogoInNavBar) {
      this.setState({
        showAppLogoInNavBar: true,
      });
    }

    if (window.scrollY < 118 && showAppLogoInNavBar) {
      this.setState({
        showAppLogoInNavBar: false,
      });
    }
  };

  getAppId = (callback) => {
    const { match } = this.props;
    if (match.params && match.params.appId) {
      const appId = match.params.appId;

      // Dialog campaing
      this.showDialogForApp(appId);

      this.setState(
        {
          appId,
        },
        callback
      );
    }
  };

  showDialogForApp = (appId) => {
    // var c = this;
    // if (appId === '20848796' || appId === '161341142' || appId === '680419302' || appId === '810802321' || appId === '218159182' || appId === '682385081' || appId === '589353554' || appId === '57404100' || appId === '906479651' || appId === '513451618' || appId === '77588943') {
    //   window.setTimeout(function () {
    //     c.showCampaignDialog(
    //       false,
    //       '',
    //       'اگر فال یلدات رو گرفتی، بعد از نصب اپلیکیشن منتظر دریافت پیامک تخفیف ویژه باش!',
    //       'متوجه شدم',
    //       '/images/pop-click.jpg',
    //     )
    //   }, 200);
    // }
  };

  showCampaignDialog = (
    campaignSkipped,
    dialogTitle,
    dialogText,
    dialogBttonText,
    dialogBannerImage
  ) => {
    this.setState({
      campaignSkipped,
      dialogTitle,
      dialogText,
      dialogBttonText,
      dialogBannerImage,
    });
  };

  campaignSkipped = () => {
    this.setState({
      campaignSkipped: true,
    });
  };

  getApp = () => {
    this.getAppId(() => {
      const { appId, viewCounted } = this.state;
      const callback = {
        success: (app) => {
          let isLandscape = false;
          app.appDetail.screenshots.forEach((screenshot) => {
            if (screenshot.dimension) {
              const sizes = screenshot.dimension.split("x");
              const width = parseInt(sizes[0]);
              const height = parseInt(sizes[1]);
              if (width > height) {
                isLandscape = true;
              }
            }
          });

          this.setState(
            {
              app,
              networkError: false,
              isLandscape,
            },
            () => {
              Api().reviewService.getAppRate(app.externalId, {
                success: (appRate) => {
                  this.setState({
                    appRate,
                  });
                },
                error: () => {
                  // TODO: handle error
                },
              });

              Api().reviewService.getAppComments(app.externalId, {
                success: (comments) => {
                  this.setState({
                    comments: comments.filter((comment) => {
                      return comment.title && comment.comment;
                    }),
                  });
                },
                error: () => {
                  // TODO: handle error
                },
              });

              if (!viewCounted) {
                Api().developerService.incrementViewCount(appId, {
                  success: () => {
                    this.setState({ viewCounted: true });
                  },
                  error: () => {},
                });
              }

              this.getDeveloperDetails();
            }
          );
        },
        error: (code) => {
          if (code === 404) {
            this.setState({
              notFoundError: true,
            });
            return;
          }
          this.setState({
            networkError: true,
          });
        },
      };

      if (appId.includes("-")) {
        Api(false).appService.getApp(appId, callback);
      } else {
        Api(false).appService.getAppWithNumericId(appId, callback);
      }
    });
  };

  getDeveloperDetails = () => {
    const { app } = this.state;
    Api().developerService.getDeveloperPublicInfo(app.developerUsername, {
      success: (data) => {
        this.setState(
          {
            developer: data,
          },
          () => {
            this.getMoreDeveloperApps();
          }
        );
      },
      error: () => {
        // TODO: handle error
      },
    });
  };

  getMoreDeveloperApps = () => {
    const { app } = this.state;

    if (app) {
      Api().appService.appsByDeveloper(app.developerExternalId, 0, {
        success: (apps) => {
          if (apps.length > 1) {
            this.setState({
              developerApps: apps.filter(function (a) {
                return a.externalId !== app.externalId;
              }),
            });
          }
        },
        error: () => {
          // Do nothing
        },
      });
    }
  };

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };
  replaceAll = (str, match, replacement) => {
    return str.replace(
      new RegExp(this.escapeRegExp(match), "g"),
      () => replacement
    );
  };

  getText = (text, full) => {
    if (!text) {
      return "";
    }

    text = ReactEscapeHtml`${text}`.__html;

    text = md({
      html: true,
      xhtmlOut: true,
      breaks: true,
      linkify: false,
    })
      .disable(["link", "image"])
      .render(`${text}`);
    if (text && text.length > 0) {
      text = this.replaceAll(text, "\n", "<br />");
    }

    if (!full) {
      text =
        text.substr(0, text.length > 200 ? 200 : text.length) +
        (text.length > 200 ? "..." : "");
    }

    return text;
  };

  showFullText = (e) => {
    e.preventDefault();
    this.setState({
      showFullDescription: true,
    });
  };
  showFullChangelog = (e) => {
    e.preventDefault();
    this.setState({
      showFullChangelog: true,
    });
  };

  stringForDate = (date) => {
    const publishDate = new Date(date);
    return getPersianDateString(publishDate);
  };

  persianDevice = (name) => {
    switch (name) {
      case "UNIVERSAL":
        return "آیفون و آیپد";
      case "IPHONE":
        return "آیفون";
      default:
        return "آیپد";
    }
  };

  onBackButtonClicked = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.length > 2 ? history.goBack() : history.push("/");
  };

  onDownloadClicked = () => {
    this.setState({
      showAppDownloadMenu: true,
    });
  };

  downloadRequiresLogin = () => {
    this.setState({
      showAppDownloadMenu: false,
      showDownloadForApp: null,
    });

    const { toggleLoginView } = this.props;

    toggleLoginView(true);
  };

  onDownloadMenuCanceled = () => {
    this.setState({
      showAppDownloadMenu: false,
      showDownloadForApp: null,
    });
  };

  showSubscriptionPackages = () => {
    const { history } = this.props;
    const { appId } = this.state;
    history.push({
      pathname: "/packages",
      state: { checkUserAuthentication: false, redirectedAppId: appId },
    });
  };

  onDeveloperAppDownloadButtonClicked = (app) => {
    this.setState({
      showDownloadForApp: app,
    });
  };

  checkAppIdChanges = () => {
    const { appId } = this.state;
    const { match } = this.props;
    if (match.params && match.params.appId) {
      const propsAppId = match.params.appId;
      if (propsAppId !== appId) {
        this.setState(
          {
            appId: propsAppId,
          },
          () => {
            window.scrollTo(0, 0);
            this.getApp();
          }
        );
      }
    }
  };

  getDescriptionForMetaTags = (app) => {
    let desc = "";
    if (
      !app.appDetail.descriptionFa ||
      app.appDetail.descriptionFa.length <= 164
    ) {
      desc = app.appDetail.descriptionFa;
    } else {
      desc = app.appDetail.descriptionFa.substring(0, 164);
    }

    return `دانلود ${app.type === "APP" ? "اپلیکیشن" : "بازی"} ${
      app.appDetail.title
    } برای iOS آخرین نسخه | ${desc}`;
  };

  render() {
    const {
      app,
      appRate,
      isLandscape,
      showFullDescription,
      showFullChangelog,
      developer,
      comments,
      showAppLogoInNavBar,
      showAppDownloadMenu,
      networkError,
      developerApps,
      showDownloadForApp,
      notFoundError,
      campaignSkipped,
      dialogTitle,
      dialogText,
      dialogBttonText,
      dialogBannerImage,
      showMiniCampaignModal,
      showAppDeveloperModal,
    } = this.state;
    const { location } = this.props;
    const iOSVersion = parseFloat(Platform.os.version);
    const qsParams = qs.parse(location.search);

    this.checkAppIdChanges();

    if (notFoundError) {
      return (
        <div className="app-details">
          <div className="view-navigation-bar">
            <a href="#" onClick={this.onBackButtonClicked}>
              <img src="/images/icon-back.svg" alt="" />
            </a>
          </div>
          <div className="not-found-error">
            <img src="/images/error-not-found.png" alt="" />
            <p>اپلیکیشن مورد نظر یافت نشد</p>
          </div>
        </div>
      );
    }

    if (networkError) {
      return (
        <div className="app-details">
          <div className="view-navigation-bar">
            <a href="#" onClick={this.onBackButtonClicked}>
              <img src="/images/icon-back.svg" alt="" />
            </a>
          </div>
          <NetworkErrorMessage
            buttonAction={() => {
              this.setState({
                networkError: false,
              });
              this.getApp();
            }}
          />
        </div>
      );
    }

    return (
      <div className="app-details">
        {showAppDeveloperModal && <IOS16DeveloperMode />}
        {!campaignSkipped ? (
          <div className="campaign-dialog">
            <CampaignDialog
              submit={() => {
                this.campaignSkipped();
                this.onDownloadClicked();
              }}
              onSkip={() => {
                window.location =
                  "https://iapps.ir/blog/yalda?utm_source=pwa&utm_medium=in_app_popup&utm_campaign=iApps_Yalda1400_Login&utm_id=iapps";
              }}
              dialogTitle={dialogTitle}
              dialogText={dialogText}
              dialogBttonText={dialogBttonText}
              dialogBannerImage={dialogBannerImage}
              cancelButtonTitle="فال نگرفتم :("
            />
          </div>
        ) : (
          ""
        )}
        {app ? (
          <Helmet>
            <title>
              دانلود {app.type === "APP" ? "اپلیکیشن" : "بازی"}{" "}
              {app.appDetail.title} برای آیفون | آی اپس - اپ استور ایرانی
            </title>
            <meta
              name="description"
              content={this.getDescriptionForMetaTags(app)}
            />
            <link
              rel="canonical"
              href={canonicalUrlForApp(
                app.appDetail.title,
                app.externalNumericId
              )}
            />
          </Helmet>
        ) : (
          ""
        )}
        <div className="view-navigation-bar">
          <a id="back-button" href="#" onClick={this.onBackButtonClicked}>
            <img src="/images/icon-back.svg" alt="" />
          </a>
          {app && showAppLogoInNavBar ? (
            <img
              className="app-logo"
              src={getImageUrlForFileId(app.appDetail.iconFileId, 38, 38)}
              alt=""
            />
          ) : (
            ""
          )}
          {app && showAppLogoInNavBar ? (
            <AppDownloaderV2
              app={app}
              setShowDeveloperModal={() =>
                this.setState({ showAppDeveloperModal: true })
              }
            />
          ) : (
            ""
          )}
        </div>
        : ''
        {!app ? (
          <LoadingSpinner />
        ) : (
          <div className="container">
            <div className="basic-info">
              <div className="app-items">
                <div className="app-icon">
                  <img
                    src={getImageUrlForFileId(
                      app.appDetail.iconFileId,
                      250,
                      250
                    )}
                    alt={app.appDetail.title}
                  />
                </div>
                <div className="details">
                  <h1>{app.appDetail.title}</h1>
                  <p>{app.category.title}</p>
                  {qsParams && qsParams.openApp === "true" ? (
                    <a
                      href={`iapps://showApp?id=${app.externalId}`}
                      className="button"
                    >
                      دریافت
                    </a>
                  ) : (
                    <>
                      {/* <button
                        id="download-button"
                        disabled={
                          iOSVersion <
                            parseFloat(app.appVersion.minOsVersion) ||
                          app.appVersion.unsupported
                        }
                        className="button"
                        onClick={this.onDownloadClicked}
                      >
                        {iOSVersion < parseFloat(app.appVersion.minOsVersion) ||
                        app.appVersion.unsupported
                          ? "ناسازگار"
                          : "دریافت"}
                      </button> */}
                      <AppDownloaderV2
                        app={app}
                        lightVariant={false}
                        setShowDeveloperModal={() => {
                          this.setState({ showAppDeveloperModal: true });
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              {/*TODO: share button removed, check if this feature is possible in ios add it again*/}
              {/*<div className="share">*/}
              {/*<button className="share-button"><img src="/images/icon-more.svg" alt="اشتراک" /></button>*/}
              {/*</div>*/}
            </div>
            {appRate ? (
              <div className="app-rating">
                <div className="rating">
                  <p className="rate">{appRate.rate}</p>
                  <Ratings
                    rating={appRate.rate}
                    widgetDimensions="20px"
                    widgetSpacings="0px"
                    widgetRatedColors="#8e8e93"
                  >
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                  </Ratings>
                </div>
                <p className="rate-count">{appRate.rateCount} نظر</p>
              </div>
            ) : (
              ""
            )}
            <div className="screenshots">
              <div className="scroller">
                {app.appDetail.screenshots.map((screenshot) => {
                  return (
                    <img
                      className={classNames({ landscape: isLandscape })}
                      src={getImageUrlForFileId(screenshot.fileId, 375, 667)}
                      alt={app.appDetail.title}
                      key={screenshot.externalId}
                    />
                  );
                })}
              </div>
              <hr className="separator" />
            </div>
            <section itemProp="description" className="description">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.getText(
                    app.appDetail.descriptionFa,
                    showFullDescription
                  ),
                }}
              />
              {!showFullDescription &&
              app &&
              app.appDetail &&
              app.appDetail.descriptionFa &&
              app.appDetail.descriptionFa.length > 200 ? (
                <a href="#" className="more-button" onClick={this.showFullText}>
                  بیشتر
                </a>
              ) : (
                ""
              )}
            </section>
            {developer ? (
              <div className="developer">
                <div className="container">
                  <div className="details">
                    <p>توسعه دهنده</p>
                    <Link
                      to={{
                        pathname: `/developers/${app.developerExternalId}`,
                        state: {
                          sectionTitle: developer.displayName,
                        },
                      }}
                    >
                      {developer.displayName}
                    </Link>
                  </div>
                  <Link
                    to={{
                      pathname: `/developers/${app.developerExternalId}`,
                      state: {
                        sectionTitle: developer.displayName,
                      },
                    }}
                  >
                    <img src="/images/more.svg" alt="" />
                  </Link>
                </div>
                <hr className="separator" />
              </div>
            ) : (
              ""
            )}
            <div className="change-log">
              <div className="container">
                <h3>تغییرات آخرین نسخه</h3>
                <div className="version-details">
                  <p className="version">نسخه {app.appVersion.versionName}</p>
                  <p className="release-date">
                    {this.stringForDate(app.appDetail.publishDate)}
                  </p>
                </div>
                <p
                  className="change-log-text"
                  dangerouslySetInnerHTML={{
                    __html: this.getText(
                      app.appVersion.changelogFa,
                      showFullChangelog
                    ),
                  }}
                />
                {!showFullChangelog ? (
                  <a
                    href="#"
                    className="more-button"
                    onClick={this.showFullChangelog}
                  >
                    بیشتر
                  </a>
                ) : (
                  ""
                )}
                <hr className="separator" />
              </div>
            </div>
            <div className="review-rating">
              <div className="review-rating-container">
                <h3>نظر و امتیاز</h3>
                {appRate &&
                appRate.rateCount &&
                parseInt(appRate.rateCount) > 0 ? (
                  <>
                    <div className="rating">
                      <p className="rate">
                        {appRate && appRate.rate ? appRate.rate.toFixed(1) : 0}
                      </p>
                      <p className="rate-count">
                        از {appRate && appRate.rate ? appRate.rateCount : 0}{" "}
                        امتیاز
                      </p>
                    </div>
                    <div className="star-ratings">
                      <div className="5-star star-info">
                        <LineProgressBar
                          percent={
                            appRate
                              ? (appRate.fiveStarRateCount * 100) /
                                appRate.rateCount
                              : 0
                          }
                          strokeColor="#8e8e93"
                          strokeWidth={2}
                          trailColor="#f2f2f8"
                          trailWidth={2}
                        />
                        <Ratings
                          rating={5}
                          widgetDimensions="11px"
                          widgetSpacings="0px"
                          widgetRatedColors="#8e8e93"
                        >
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                        </Ratings>
                      </div>
                      <div className="4-star star-info">
                        <LineProgressBar
                          percent={
                            appRate
                              ? (appRate.fourStarRateCount * 100) /
                                appRate.rateCount
                              : 0
                          }
                          strokeColor="#8e8e93"
                          strokeWidth={2}
                          trailColor="#f2f2f8"
                          trailWidth={2}
                        />
                        <Ratings
                          rating={4}
                          widgetDimensions="11px"
                          widgetSpacings="0px"
                          widgetRatedColors="#8e8e93"
                        >
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                        </Ratings>
                      </div>
                      <div className="3-star star-info">
                        <LineProgressBar
                          percent={
                            appRate
                              ? (appRate.threeStarRateCount * 100) /
                                appRate.rateCount
                              : 0
                          }
                          strokeColor="#8e8e93"
                          strokeWidth={2}
                          trailColor="#f2f2f8"
                          trailWidth={2}
                        />
                        <Ratings
                          rating={3}
                          widgetDimensions="11px"
                          widgetSpacings="0px"
                          widgetRatedColors="#8e8e93"
                        >
                          <Ratings.Widget />
                          <Ratings.Widget />
                          <Ratings.Widget />
                        </Ratings>
                      </div>
                      <div className="2-star star-info">
                        <LineProgressBar
                          percent={
                            appRate
                              ? (appRate.twoStarRateCount * 100) /
                                appRate.rateCount
                              : 0
                          }
                          strokeColor="#8e8e93"
                          strokeWidth={2}
                          trailColor="#f2f2f8"
                          trailWidth={2}
                        />
                        <Ratings
                          rating={2}
                          widgetDimensions="11px"
                          widgetSpacings="0px"
                          widgetRatedColors="#8e8e93"
                        >
                          <Ratings.Widget />
                          <Ratings.Widget />
                        </Ratings>
                      </div>
                      <div className="1-star star-info">
                        <LineProgressBar
                          percent={
                            appRate
                              ? (appRate.oneStarRateCount * 100) /
                                appRate.rateCount
                              : 0
                          }
                          strokeColor="#8e8e93"
                          strokeWidth={2}
                          trailColor="#f2f2f8"
                          trailWidth={2}
                        />
                        <Ratings
                          rating={2}
                          widgetDimensions="11px"
                          widgetSpacings="0px"
                          widgetRatedColors="#8e8e93"
                        >
                          <Ratings.Widget />
                        </Ratings>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {comments ? (
              <div className="comments">
                <div className="scroller">
                  {comments.map((item, index) => {
                    return (
                      <div className="comment" key={index}>
                        <div className="header">
                          <p className="title">{item.title}</p>
                          <p className="date">
                            {this.stringForDate(item.createdAt)}
                          </p>
                        </div>
                        <div className="comment-rate">
                          <Ratings
                            rating={item.rate}
                            widgetDimensions="16px"
                            widgetSpacings="0px"
                            widgetRatedColors="#ff9502"
                          >
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                          </Ratings>
                        </div>
                        <p className="text">{item.comment}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="write-review">
              <Link to={`/submitReview/${app.externalId}`}>
                نظر خود را بنویسید{" "}
                <img src="/images/icon-write-review.svg" alt="" />
              </Link>
              <hr className="separator" />
            </div>
            <div className="information">
              <div className="info-container">
                <h3>اطلاعات برنامه</h3>
                <div className="info">
                  <p className="title">سازگاری</p>
                  <p className="value">
                    {this.persianDevice(app.appVersion.compatibility)}
                  </p>
                </div>
                <div className="info">
                  <p className="title">دسته بندی</p>
                  <p className="value">{app ? app.category.title : ""}</p>
                </div>
                <div className="info">
                  <p className="title">حجم</p>
                  <p className="value">
                    {app
                      ? (app.appVersion.size / 1000 / 1000).toFixed(0) +
                        " مگابایت"
                      : ""}
                  </p>
                </div>
                <div className="info">
                  <p className="title">سازگاری با سیستم عامل</p>
                  <p className="value">
                    {app ? app.appVersion.minOsVersion + " به بالا" : ""}
                  </p>
                </div>
                {app && app.appStorePrice ? (
                  <div className="info">
                    <p className="title">قیمت اپ استور</p>
                    <p className="value">{app.appStorePrice}</p>
                  </div>
                ) : null}
              </div>
            </div>
            {developerApps ? (
              <div className="developer-apps">
                <DeveloperAppsSection
                  apps={developerApps}
                  onDownloadButtonClicked={
                    this.onDeveloperAppDownloadButtonClicked
                  }
                  developerExternalId={app.developerExternalId}
                  developerDisplayName={developer.displayName}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="downloader-container">
          {showAppDownloadMenu || showDownloadForApp ? (
            <AppDownloader
              app={showDownloadForApp ? showDownloadForApp : app}
              hideEnterprise={app.externalNumericId === 12207678}
              onCanceled={this.onDownloadMenuCanceled}
              onPackageRequired={this.showSubscriptionPackages}
              onLoginRequired={this.downloadRequiresLogin}
            />
          ) : (
            ""
          )}
        </div>
        {!isStandalone() ? (
          <div className="install-message">
            <div className="arrow_box">
              <div className="manual">
                <strong>آی‌اپس را بر روی دستگاه خود نصب کنید</strong>
                <br />
                دکمه‌ی
                <img src="/images/share-icon-iphone-20.jpg" alt="" />
                را لمس کنید و Add to Home Screen را انتخاب نمایید.
              </div>
              <img src="/images/2Add_to_home_screen_icon.png" alt="" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(AppDetails));
