import React, { Component } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import classNames from "classnames";

import DownloadProfile from "./components/DownloadProfile";
import OpenSettings from "./components/OpenSettings";
import WaitingForRegistration from "./components/WaitingForRegistration";
import SubscriptionProcessLayout from "../subscription-packages/v2/SubscriptionProcessLayout";

class DeviceRegistrationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
    };
  }

  onProfileDownloaded = () => {
    this.setState({
      progress: 49,
    });
  };

  onOpenSettings = () => {
    this.setState({
      progress: 99,
    });
  };

  render() {
    const { progress } = this.state;

    let activeStep = (
      <DownloadProfile onProfileDownloaded={this.onProfileDownloaded} />
    );
    switch (progress) {
      case 49:
        activeStep = <OpenSettings onOpenSettings={this.onOpenSettings} />;
        break;
      case 99:
        activeStep = (
          <WaitingForRegistration
            token={localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN")}
          />
        );
        break;
      case 100:
        break;
      default:
        break;
    }

    return (
      <SubscriptionProcessLayout>
        <div className="device-registration-wizard">
          <div className="container">{activeStep}</div>
        </div>
      </SubscriptionProcessLayout>
    );
  }
}

export default DeviceRegistrationWizard;
