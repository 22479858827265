import React from "react";

const MasterkalaCampaign = function () {
  return (
    <div
      style={{
        backgroundColor: "#FFF",
        minHeight: "100vh",
        width: "100vw",
        overflowX: "hidden",
        padding: "24px 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="imaster"
        src="./images/imaster-campaign.webp"
        style={{ maxWidth: "80vw" }}
      />
      <a
        href="/packages"
        style={{
          margin: "12px",
          display: "relative",
          width: "50vw",
          fontWeight: 700,
          fontSize: "16px",
          color: "#ffffff",
          textAlign: "center",
          padding: "14px 60px",
          background: "#186BE7",
          boxShadow: "0px 6px 26px rgba(16, 30, 115, 0.06)",
          borderRadius: "12px",
        }}
      >
        شرکت در قرعه کشی!
      </a>
    </div>
  );
};

export default React.memo(MasterkalaCampaign);
