import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ClipLoader, PulseLoader } from "react-spinners";
import moment from "moment-jalaali";

import NoSubscriptionPackages from "./components/NoSubscriptionPackages";
import Api from "../../shared/networking/Api";
import SubscriptionPackage from "./components/SubscriptionPackage";
import Dialog from "../../shared/components/Dialog";
import RequestApp from "./components/RequestApp";
import RegisterModal from "../../shared/components/RegisterModal";

class Profile extends Component {
  constructor(props) {
    super(props);

    const savedUsername = localStorage.getItem("PROFILE_USERNAME");
    const activeSubscriptionPackage = localStorage.getItem(
      "PROFILE_SUBSCRIPTION_IDS",
    );
    const validUntil = localStorage.getItem("PROFILE_VALID_UNTIL");
    let remainingDays = null;
    if (validUntil) {
      const now = moment();
      const targetDate = moment(parseInt(validUntil));
      const diffDays = targetDate.diff(now, "days");
      if (diffDays > 0) {
        remainingDays = diffDays;
      }
    }

    this.state = {
      username: savedUsername ? savedUsername.replace("+98", "0") : "",
      activeSubscriptionPackage: activeSubscriptionPackage
        ? JSON.parse(activeSubscriptionPackage)
        : null,
      showLogOutDialog: false,
      remainingDays,
      deviceRegistrationLoading: false,
      showRequestAppView: false,
      showRegisterModal: false,
      isDeviceRegistrationFailed: false,
      isDeviceRegistered: false,
      isDeviceRegistrationProcessing: false,
      deviceStatusLoading: true,
    };
  }

  componentDidMount() {
    Api().accountService.userDetails({
      success: (response) => {
        if (response && response.username) {
          if (localStorage.getItem("PROFILE_USERNAME") !== response.username) {
            try {
              localStorage.setItem("PROFILE_USERNAME", response.username);
            } catch (e) {}

            this.setState(
              {
                username: response.username.replace("+98", "0"),
              },
              () => {
                this.getActiveSubscriptionPackages();
              },
            );
          } else {
            this.getActiveSubscriptionPackages();
          }
        }
      },
      error: () => {
        // Do nothing
      },
    });
    const mobileInfoToken = localStorage.getItem("MOBILE_CONFIG_INFO_TOKEN");

    Api(false).accountService.isDeviceRegistered(mobileInfoToken, {
      success: (response) => {
        this.setState({ deviceStatusLoading: false });
        if (response.isDeviceRegistered === "true") {
          this.setState({ isDeviceRegistered: true });
        }
        if (response.isProcessing === "true") {
          this.setState({ isDeviceRegistrationProcessing: true });
        }
        if (response.isFailed === "true") {
          this.setState({ isDeviceRegistrationFailed: true });
        }
      },
      error: (code) => {
        this.setState({ deviceStatusLoading: false });
        console.log(code);
      },
    });
  }

  showLogoutDialog = () => {
    this.setState({
      showLogOutDialog: true,
    });
  };

  logOut = () => {
    this.setState({
      showLogOutDialog: false,
    });

    localStorage.removeItem("PROFILE_USERNAME");
    localStorage.removeItem("PROFILE_SUBSCRIPTION_IDS");
    localStorage.removeItem("REGISTERED_MOBILE_CONFIG_INFO_TOKEN");
    localStorage.removeItem("MOBILE_CONFIG_INFO_TOKEN");
    localStorage.removeItem("PROFILE_VALID_UNTIL");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_refresh_token");

    const { onLogOut } = this.props;

    if (onLogOut) {
      onLogOut();
    }
  };

  getActiveSubscriptionPackages = () => {
    Api().ordersService.userSubscriptionPackages({
      success: (response) => {
        if (response.activeSubscriptionPackages) {
          const activeSubscriptionPackages =
            response.activeSubscriptionPackages;

          let activeSubPackage = null;

          if (
            activeSubscriptionPackages &&
            activeSubscriptionPackages.length > 0
          ) {
            activeSubPackage = activeSubscriptionPackages[0];

            // If user has more than one subscriotion package, ADHOC subscription package is prefered to be selected as active subscription
            activeSubscriptionPackages.forEach((element) => {
              if (element.type === "ADHOC") {
                activeSubPackage = element;
              }
            });
            //
          }
          let validUntil = activeSubPackage.validUntil;

          if (activeSubPackage) {
            try {
              localStorage.setItem(
                "PROFILE_SUBSCRIPTION_IDS",
                JSON.stringify(activeSubPackage),
              );
            } catch (e) {}

            const now = moment();
            const targetDate = moment(validUntil);
            const remainingDays = targetDate.diff(now, "days");

            try {
              localStorage.setItem("PROFILE_VALID_UNTIL", `${validUntil}`);
            } catch (e) {}

            this.setState({
              activeSubscriptionPackage: activeSubPackage,
              remainingDays,
            });
          }
        }
      },
      error: (err) => {
        localStorage.removeItem("PROFILE_SUBSCRIPTION_IDS");
      },
    });
  };

  getTokenAndRedirect = () => {
    const { history } = this.props;
    const { deviceRegistrationLoading } = this.state;

    if (deviceRegistrationLoading) {
      return;
    }

    this.setState({
      deviceRegistrationLoading: true,
    });

    Api().accountService.requestMobileConfigInfo({
      success: (response) => {
        if (response.token) {
          try {
            localStorage.setItem("MOBILE_CONFIG_INFO_TOKEN", response.token);
          } catch (e) {}
          history.push("/register-device-tutorial");
        }

        this.setState({
          deviceRegistrationLoading: false,
        });
      },
      error: () => {
        this.setState({
          deviceRegistrationLoading: false,
        });
      },
    });

    this.setState({
      loading: false,
    });
  };

  onRequestAppPressed = () => {
    this.setState({
      showRequestAppView: true,
    });
  };

  showRegisterModal = () => {
    this.setState({
      showRegisterModal: true,
    });
  };

  hideRegisterModal = () => {
    this.setState({
      showRegisterModal: false,
    });
  };

  goToSupport = () => {
    const { history } = this.props;
    history.push("/support");
  };

  getMenu = (hasAdhocSubscription) => {
    const { deviceRegistrationLoading } = this.state;

    let menuItems = [
      // {
      //   icon: "icon-app-store.png",
      //   onClick: this.onRequestAppPressed,
      //   title: "درخواست اپلیکیشن جدید",
      // },
      {
        icon: "support-icon.svg",
        onClick: this.goToSupport,
        title: "پشتیبانی",
      },
      {
        icon: "exit-icon.svg",
        onClick: this.showLogoutDialog,
        title: "خروج از حساب",
      },
    ];

    if (hasAdhocSubscription) {
      menuItems = [
        // {
        //   icon: "register-icon.png",
        //   onClick: this.getTokenAndRedirect,
        //   title: deviceRegistrationLoading ? "لطفا صبر کنید..." : "ثبت دستگاه",
        // },
        ...menuItems,
      ];
    }

    return menuItems.map((item, index) => {
      return (
        <li onClick={item.onClick} key={index}>
          <div>
            <img src={`/images/${item.icon}`} alt="item" />
            <h3>{item.title}</h3>
          </div>
          <div>{item.left && <h2>{item.left}</h2>}</div>
        </li>
      );
    });
  };

  hideRequestAppView = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      showRequestAppView: false,
    });
  };

  render() {
    const {
      username,
      activeSubscriptionPackage,
      showLogOutDialog,
      remainingDays,
      showRequestAppView,
      showRegisterModal,
      isDeviceRegistrationProcessing,
      isDeviceRegistrationFailed,
      isDeviceRegistered,
      deviceRegistrationLoading,
      deviceStatusLoading,
    } = this.state;

    let hasAdhocSub = false;
    if (activeSubscriptionPackage) {
      hasAdhocSub = activeSubscriptionPackage.type === "ADHOC";
    }

    const notRegistered =
      isDeviceRegistrationProcessing === false &&
      isDeviceRegistrationFailed === false &&
      isDeviceRegistered === false;

    let message;
    if (isDeviceRegistrationProcessing) {
      message = "✅ اشتراک شما با موفقیت خریداری شده است.";
    } else if (isDeviceRegistrationFailed) {
      message =
        "توجه داشته باشید از مدت زمان اشتراک شما کم نمی شود و پس از رفع مشکل از سمت اپل اکانت شما فعال می‌شود";
    } else if (notRegistered) {
      message =
        "اشتراک شما با موفقیت خریداری شده است. در حال حاضر شما باید دستگاه خود را ثبت کنید.";
    } else if (activeSubscriptionPackage) {
      message =
        "حساب شما دارای اشتراک فعال است و دستگه شما ثبت شده است. برای خرید اشتراک جدید و یا تمدید اشتراک فعلی از طریق گزینه زیر اقدام کنید.";
    }

    return (
      <div className="profile">
        <div className="header">
          <h1>اطلاعات کاربر</h1>
          <p className="username">{username ? username : "..."}</p>
        </div>

        {deviceStatusLoading ? (
          <div className="profile-loading" />
        ) : (
          <div className="user-subscription-package">
            {activeSubscriptionPackage && (
              <div className="subscription-header">
                <h3>سرویس اشتراکی</h3>

                <div className="device-status">
                  <p className="device-status__label">وضعیت دستگاه</p>
                  {isDeviceRegistrationFailed && (
                    <p className="not-registered">بلاک‌شده</p>
                  )}

                  {isDeviceRegistrationProcessing ? (
                    <p className="processing">در انتظار تایید</p>
                  ) : isDeviceRegistered ? (
                    <p className="registered">ثبت‌شده</p>
                  ) : null}

                  {notRegistered && <p className="disabled">ثبت‌نشده</p>}
                </div>
              </div>
            )}
            {activeSubscriptionPackage ? (
              <SubscriptionPackage
                hidePrice
                subscriptionPackage={activeSubscriptionPackage}
                index={activeSubscriptionPackage.colorType}
                remainingDays={remainingDays}
                blocked={isDeviceRegistrationFailed}
                processing={isDeviceRegistrationProcessing}
                registered={isDeviceRegistered}
                notRegistered={notRegistered}
              />
            ) : (
              <NoSubscriptionPackages />
            )}
            {message && !isDeviceRegistered && activeSubscriptionPackage ? (
              <div className="active-account-alert">
                <p className="message">{message}</p>

                <button onClick={this.getTokenAndRedirect}>
                  {deviceRegistrationLoading ? (
                    <PulseLoader color="white" size={8} />
                  ) : (
                    "ثبت دستگاه"
                  )}
                </button>
              </div>
            ) : null}
            {message && activeSubscriptionPackage && isDeviceRegistered && (
              <div className="active-account-alert">
                <p className="message">{message}</p>

                <button
                  onClick={() => {
                    this.props.history.push("/packages");
                  }}
                >
                  تمدید اشتراک
                </button>
              </div>
            )}
          </div>
        )}
        <ul className="menu">{this.getMenu(hasAdhocSub)}</ul>
        {showLogOutDialog ? (
          <Dialog
            title="خروج از حساب کاربری"
            message="آیا مطمئن هستید می خواهید خارج شوید؟"
            positiveButtonTitle="بله"
            negativeButtonTitle="خیر"
            onPositiveButtonClicked={this.logOut}
            onNegativeButtonClicked={() => {
              this.setState({ showLogOutDialog: false });
            }}
          />
        ) : (
          ""
        )}
        {showRequestAppView ? (
          <a href="#" onClick={this.hideRequestAppView}>
            <RequestApp onCancel={this.hideRequestAppView} />
          </a>
        ) : (
          ""
        )}
        {showRegisterModal && (
          <RegisterModal setShow={this.hideRegisterModal} />
        )}
      </div>
    );
  }
}

export default withRouter(Profile);

export function removeUserData() {
  localStorage.removeItem("PROFILE_USERNAME");
  localStorage.removeItem("PROFILE_SUBSCRIPTION_IDS");
  localStorage.removeItem("PROFILE_SUBSCRIPTION_PACKAGES");
  localStorage.removeItem("REGISTERED_MOBILE_CONFIG_INFO_TOKEN");
  localStorage.removeItem("MOBILE_CONFIG_INFO_TOKEN");
  localStorage.removeItem("PROFILE_VALID_UNTIL");
  localStorage.removeItem("auth_token");
  localStorage.removeItem("auth_refresh_token");
}
