import React, { Component } from "react";
import Lottie from "react-lottie-player";
import { withRouter } from "react-router-dom";
import animation from "./developer.json";

class IOS16DeveloperMode extends Component {
  abimationJson = animation;
  constructor(props) {
    super(props);

    this.state = {
      close: false,
      loading: true,
      showAgain: true,
    };
  }

  closeModal = (e) => {
    if (!e) {
      this.setState({ close: true });
      return;
    }

    if (
      e &&
      e.target &&
      (e.target.id === "modal-with-gif-container" ||
        e.target.id === "modal-with-gif" ||
        e.target.id === "closeButton")
    ) {
      this.setState({ close: true });
    }
  };

  removeModalShow() {
    let prev = localStorage.getItem("app_downloaded");
    localStorage.setItem("app_downloaded", prev === "0" ? 1 : 0);
  }

  componentDidMount() {
    // Test the navigator.userAgent string for the presence of "iPad", "iPhone", or "iPod"
    let haveDownloaded = localStorage.getItem("app_downloaded");
    if (haveDownloaded === "1") {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        // Device is an iOS device
        // Extract the iOS version number from the userAgent string
        var iOSVersion = /\b(i(?:Pad|Phone|Pod))(?:.*Version\/([\d.]+))?/.exec(
          navigator.userAgent
        );
        let grandVersion = iOSVersion[2].split(".")[0];
        if (Number(grandVersion) >= 16) {
          this.setState({ loading: false });
          console.log("This device is running iOS version " + grandVersion);
        }
      }
    }
  }

  render() {
    const { close, loading } = this.state;
    if (close || loading) {
      return "";
    }

    return (
      <div
        id="modal-with-gif-container"
        class="modal-with-gif-container"
        onClick={this.closeModal}
      >
        <div
          className="modal-with-gif"
          id="modal-with-gif"
          style={{ height: "497px" }}
        >
          <div className="gif-header">
            <img
              src="/images/iapps-logo-typography-blue.png"
              alt="iApps logo"
            />
            <a
              id="closeButton"
              href="#closeButton"
              onClick={(e) => {
                e.preventDefault();
                this.closeModal();
              }}
            >
              <img
                id="close-button-image"
                src="/images/campagin-modal-close-icon.png"
                alt="close button"
              />
            </a>
          </div>
          <div className="content">
            <div style={{ padding: "0 56px" }}>
              <Lottie
                animationData={animation}
                play
                loop={true}
                speed={0.6}
                style={{ width: "100%" }}
              />
            </div>
            <div
              className="main-content"
              style={{
                padding: "0 30px",
                marginTop: "30px",
                display: "grid",
                gap: "16px",
              }}
            >
              <p>
                همین حالا با کمک این آموزش، بعد از نصب اولین اپلیکیشن{" "}
                <span>Developer Mode</span> رو فعال کن تا بتونی از اپلیکیشن‌هات
                استفاده کنی!
              </p>
              <div style={{ display: "flex" }}>
                <input
                  name="show"
                  id="show"
                  type="checkbox"
                  style={{ width: "20px", height: "20px" }}
                  onChange={this.removeModalShow}
                />
                <label htmlFor="show" style={{ paddingRight: "6px" }}>
                  دیگر به من نشان نده
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IOS16DeveloperMode);
