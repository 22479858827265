import BaseService from './BaseService';

class NotificationService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  pullNotifications = (callback) => {
    this.httpClient.get('/notifications/pulls', {
      headers: {
        'X-API-Version': process.env.REACT_APP_API_VERSION,
        'X-Client-Version-Code': process.env.REACT_APP_CLIENT_VERSION_CODE,
      },
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
      }
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };
}

export default NotificationService;
