import BaseService from './BaseService';

class BillingService extends BaseService{
  /**
   *
   * @param {AxiosInstance} httpClient
   */
  constructor(httpClient) {
    super();
    this.httpClient = httpClient;
  }

  getBilling = (callback) => {
    this.httpClient.get(`/billing/bill/balance`).then((response) => {
      this.onResponse(response, callback);
    }).catch((error) => {
      this.onError(error, callback);
    });
  };

}

export default BillingService;
