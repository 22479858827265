import React, { Component } from 'react';
import AppWithScreenShotSectionItem from './AppWithScreenShotSectionItem';
import { getImageUrlForFileId } from '../../../shared/networking/Api';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import { isMobile } from '../../../shared/DeviceUtils';

class AppWithScreenShotSection extends Component {
  componentDidMount() {
    let options = {};
    if (isMobile()) {
      options = {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 12,
      };
    } else {
      options = {
        slidesPerView: 3,
        spaceBetween: 20,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
      };
    }

    const mySwiper = new Swiper('.app-with-screenshot-swiper-container', options);
  }

  render() {
    const { section, onDownloadButtonClicked } = this.props;

    const appItems = [];
    if (section.appListingSection && section.appListingSection.type === 'SINGLE_WITH_SCREENSHOT') {
      section.appListingSection.apps.forEach((app) => {
        const screenshots = [];
        app.appDetail.screenshots.forEach((screenshot) => {
          if (screenshots.length < 3) {
            screenshots.push(<div style={{backgroundImage: `url(${getImageUrlForFileId(screenshot.fileId, 230, 498)})`}} alt={app.appDetail.title} key={screenshot.externalId} />);
          }
        });
        appItems.push(<AppWithScreenShotSectionItem app={app} notSingle={true} screenshots={screenshots} key={app.externalId} onDownloadButtonClicked={onDownloadButtonClicked} sectionName={section.title} />
        );
      });
    }

    return (
      <div className="app-with-secreenshot-section">
        <div className="section-header">
          <h1>{section.title}</h1>
          <Link to={
            {
              pathname: `/apps/${section.id}`,
              state: {
                sectionTitle: section.title,
              }
            }}>بیشتر</Link>
        </div>
        <div className="scroller app-with-screenshot-swiper-container">
          <div className="swiper-wrapper">
            {appItems}
          </div>
        </div>
        <hr className="separator" />
      </div>
    );
  }
}

export default AppWithScreenShotSection;
