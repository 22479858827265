import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AddToHomeScreenMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onOkClicked = () => {
    const { onSkipClicked } = this.props;
    try {
      localStorage.setItem("is_first_visit", "false");
    } catch (e) {}
    onSkipClicked();
  };

  onSupportClicked = () => {
    window.open("https://iapps.ir/faq");
  };

  checkOSCompatibility = () => {
    return (
      !!navigator.userAgent.match(/iPhone|iPad/i) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  };

  checkBrowserCompatibility = () => {
    return !navigator.userAgent.match(/CriOS/i);
  };

  render() {
    const { onSkipClicked } = this.props;

    if (!this.checkOSCompatibility()) {
      onSkipClicked();
    }

    if (!this.checkBrowserCompatibility()) {
      return (
        <div className="browser-not-support">
          <p className="title">‌توجه</p>
          <p className="description">
            برای استفاده از تمامی امکانات آی اپس لطفا از مرورگر Safari استفاده
            کنید. <br />
            <br />
            برای این کار مرورگر فعلی را ببندید و از منو برنامه های دستگاه خود
            اپلیکیشن Safari را باز کنید سپس iapps.ir را در نوار آدرس وارد کنید.
          </p>
          <a href="#" onClick={this.onOkClicked}>
            متوجه شدم
          </a>
        </div>
      );
    }

    return (
      <div className="add-to-home-screen-message-container">
        <img
          className="background-photo"
          src="/images/illustration2.png"
          alt=""
        />
        <div className="content">
          <h3>نصب آی اپس</h3>
          <div className="manual">
            آی‌اپس را بر روی دستگاه خود نصب کنید:
            <br />
            دکمه‌ی
            <img src="/images/share-icon-iphone-20.jpg" alt="" />
            را لمس کنید و Add to Home Screen را انتخاب نمایید.
          </div>
          <div className="icon-container">
            <img
              src="/images/2Add_to_home_screen_icon.png"
              alt=""
              className="icon"
            />
          </div>
          <div className="actions">
            <a href="#" onClick={this.onOkClicked}>
              متوجه شدم
            </a>
            <a href="#" className="support" onClick={this.onSupportClicked}>
              پشتیبانی
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddToHomeScreenMessage);
