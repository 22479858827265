import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import axios from 'axios';
import md5 from 'md5';
import { toEnglishDigits } from '../shared/StringUtils';

class CampaignDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: '',
      loading: false,
    }
  }

  setPhoneNumber = (e) => {
    const n = e.target.value;
    if (n.includes('+') && n.length > 13) {
      return;
    } else if (n.length > 11) {
      return;
    }
    this.setState({
      phoneNumber: toEnglishDigits(n),
    });
  };

  submit = (e) => {
    e.preventDefault();

    const submitFunc = this.props.submit;
    if (submitFunc) {
      submitFunc();
    }
    // window.location = "https://app.iapps.ir/i/485529040"
    // let { phoneNumber } = this.state;
    // this.setState({
    //   loading: true,
    // });
    //
    // phoneNumber = phoneNumber.replace('+98', 0);
    //
    // if (!phoneNumber || phoneNumber.length < 11) {
    //   this.setState({
    //     errorMessage: 'شماره موبایل باید ۱۱ رقم باشد.',
    //     loading: false,
    //   });
    //   return
    // }
    //
    // const instance = axios.create({
    //   baseURL: 'https://snpfd.iapps.ir',
    // });
    //
    // instance.get(`/users/getVoucher/${phoneNumber}`, {
    //   headers: {
    //     'X-Token': md5(`${phoneNumber}22efih19pI2qoQXF`).toUpperCase()
    //   }
    // })
    //   .then(() => {
    //     this.setState({
    //       success: true,
    //       loading: false,
    //     });
    //   })
    //   .catch((error) => {
    //     this.setState({
    //       errorMessage: 'خطا در ارتباط با سرور لطفا مجددا تلاش کنید.',
    //       loading: false,
    //     });
    //   });
  };

  render() {
    const { phoneNumber, loading, errorMessage, success, closeModal } = this.state;
    const { onSkip, dialogTitle, dialogText, dialogBttonText, dialogBannerImage, hasPrimaryButton, cancelButtonTitle } = this.props;
    if (closeModal) {
      return '';
    }
    return (
      <div className="campaign-modal-container">
        <div className="campaign-modal">
          {!success ? <div className="campaign-modal-header">{dialogTitle}</div> : '' }
          <div className="campaign-modal-body">
            {!success ? <>
                {/*<img src="https://static.iapps.ir/apps/file/image/8f260bb7-e22e-42ec-b36c-9b7313a6decf-678388f7-06d5-4779-9261-70a47c6d5f30/250x250.jpg?key=Ndu5E2g4BVBJVAwsDk82jSeThSw6CcKq" alt="" />*/}
                <img src={dialogBannerImage} alt="" className="banner" style={{'border-radius': '10px'}} />
                {errorMessage ? <p className="error">{errorMessage}</p> : ''}
                <p>{dialogText}</p>
                {/*<Input placeholder="شمارتو وارد کن" type="number" className="campaign-input-field" value={phoneNumber} onChange={this.setPhoneNumber} />*/}
              </>
              : <>
                <img src={dialogBannerImage} alt="" style={{'border-radius': '10px'}} />
                {/*<p className="success">کد تخفیف برای شما ارسال شد.</p>*/}
              </>
            }
          </div>
          <div className="campaign-modal-footer">
            {!success && hasPrimaryButton !== false ? <Button color="primary" disabled={loading} onClick={this.submit}>{loading ? 'لطفا صبر کنید...' : dialogBttonText}</Button> : ''}
            <Button color="danger" onClick={(e) => {e.preventDefault(); onSkip();}}>{success ? 'بستن' : (cancelButtonTitle ? cancelButtonTitle : 'انصراف')}</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignDialog;
