import React from "react";
import { useHistory } from "react-router-dom";
const Support = function () {
  const history = useHistory();

  const onBackButtonClicked = (e) => {
    e.preventDefault();
    history.length > 2 ? history.goBack() : history.push("/");
  };

  return (
    <div className="container">
      <div className="header">
        <a href="#" onClick={onBackButtonClicked}>
          <img src="/images/icon-back.svg" alt="" />
        </a>
      </div>

      <div className="content">
        <img src="/images/support-hero.svg" />
        <h3>پشتیبانی</h3>
        <p>
          همه روزه می‌توانید از ساعت ۹ صبح الی ۱۲ شب با شماره پشتیبانی تماس
          بگیرید و یا از طریق ایمیل و یا پشتیبانی آنلاین با ما در ارتباط باشید؛
          همچنین ‌می‌توانید سوالات متداول را در لینک زیر مشاهده کنید.
        </p>
        <div className="buttons">
          <button
            onClick={() => (window.location.href = "tel:+982191002285")}
            className="phone-button"
          >
            <img src="/images/phone-icon.svg" />
            <p>تماس تلفنی</p>
          </button>
          <button className="chat-button" onClick={() => window.Goftino.open()}>
            <img src="/images/chat-icon.svg" />
            <p>چت با پشتیبانی</p>
          </button>
          <a href="https://iapps.ir/faq">سوالات متداول</a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Support);
