import React, { useCallback, useState } from 'react';
import Platform from 'platform';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

import Api from '../networking/Api';
import { useHistory } from 'react-router-dom';
import { getAuthToken } from '../AccountManager';

const WEB_API_BASE_URL = process.env.REACT_APP_IAPPS_API_BASE_URL;

const AppDownloaderV2 = function ({
  app,
  lightVariant = false,
  setShowDeveloperModal,
}) {
  const history = useHistory();

  const [showAdhocLoading, setShowAdhocLoading] = useState(false);
  const [gatewayRedirect, setGatewayRedirect] = useState(undefined);

  const iOSVersion = parseFloat(Platform.os.version);

  const checkDownloadStatus = useCallback((appId, appVersionId) => {
    Api().appInstallerService.checkDownloadRequested(appId, appVersionId, {
      success: (response, status) => {
        if (status === 201) {
          window.setTimeout(() => {
            checkDownloadStatus(appId, appVersionId);
          }, 2000);
        } else if (response.token) {
          window.location = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
          setShowAdhocLoading(false);
        } else if (status === 204) {
          window.setTimeout(() => {
            checkDownloadStatus(appId, appVersionId);
          }, 2000);
        }
      },
      error: (code, error) => {
        setShowAdhocLoading(false);
        toast.error('خطایی رخ  داده است٬ لطفا کمی بعد دوباره تلاش کنید.');
      },
    });
  }, []);

  const getTokenAndRedirect = useCallback(() => {
    Api().accountService.requestMobileConfigInfo({
      success: (response) => {
        setShowAdhocLoading(false);

        if (response.token) {
          try {
            localStorage.setItem('MOBILE_CONFIG_INFO_TOKEN', response.token);
          } catch (e) {}
          history.push('/register-device-tutorial');
        }
      },
      error: (code) => {
        setShowAdhocLoading(false);

        if (code === 402) {
          history.push({
            pathname: '/packages',
            state: {
              checkUserAuthentication: false,
              hideEnterprise: app.externalNumericId === 12207678,
              hideAdhoc: false,
              showCampaignDialog: app.externalNumericId === 12207678,
              redirectedAppId: app.externalNumericId,
            },
          });
        }
      },
    });

    setShowAdhocLoading(true);
  }, [app.externalNumericId, history]);

  const onDownloadClickHandler = useCallback(() => {
    const token = getAuthToken();
    if (!token) {
      history.push('/account');
      return;
    }
    if (
      iOSVersion < parseFloat(app.appVersion.minOsVersion) ||
      app.appVersion.unsupported
    ) {
      toast('این اپلیکیشن با دستگاه شما سازگار نیست.', { type: 'error' });
      return;
    }

    let local = localStorage.getItem('app_downloaded');
    if (!local) {
      localStorage.setItem('app_downloaded', 1);
      if (setShowDeveloperModal) setShowDeveloperModal(true);
    }

    setShowAdhocLoading(true);

    // Check if has token and token is registered
    const mobileInfoToken = localStorage.getItem('MOBILE_CONFIG_INFO_TOKEN');
    const registeredMobileInfoToken = localStorage.getItem(
      'REGISTERED_MOBILE_CONFIG_INFO_TOKEN'
    );

    if (!registeredMobileInfoToken && !mobileInfoToken) {
      getTokenAndRedirect();
      return;
    }

    let isTokenEmpty = false;
    if (
      !registeredMobileInfoToken ||
      registeredMobileInfoToken !== mobileInfoToken
    ) {
      if (mobileInfoToken) {
        Api(false).accountService.isDeviceRegistered(mobileInfoToken, {
          success: (response) => {
            if (response.isProcessing === 'true') {
              toast(
                'ثبت دستگاه شما در حال پردازش است؛ پس از اینکه وضعیت دستگاه به «ثبت‌شده» تغییر یافت، می‌توانید به نصب اپلیکیشن بپردازید.',
                { type: 'warning' }
              );
            } else if (
              response.isDeviceRegistered === 'true' &&
              response.isProcessing === 'false'
            ) {
              try {
                localStorage.setItem(
                  'REGISTERED_MOBILE_CONFIG_INFO_TOKEN',
                  mobileInfoToken
                );
              } catch (e) {}
              onDownloadClickHandler();
            } else {
              getTokenAndRedirect();
            }
          },
          error: (code) => {
            if (code === 404) {
              isTokenEmpty = true;
            } else {
              // TODO: handle error
            }
          },
        });
      } else {
        isTokenEmpty = true;
      }

      return;
    }

    if (isTokenEmpty) {
      getTokenAndRedirect();
      return;
    }

    Api().appInstallerService.requestDownload(
      app.externalId,
      'adhoc',
      true,
      mobileInfoToken,
      app.appVersion.externalId,
      {
        success: (response, status) => {
          if (response && response.token) {
            window.location = `itms-services://?action=download-manifest&url=${WEB_API_BASE_URL}/appInstaller/${response.token}.plist`;
            setShowAdhocLoading(false);
          } else if (status === 402) {
            history.push({
              pathname: '/packages',
              state: {
                checkUserAuthentication: false,
                hideEnterprise: app.externalNumericId === 12207678,
                hideAdhoc: false,
                showCampaignDialog: app.externalNumericId === 12207678,
                redirectedAppId: app.externalNumericId,
              },
            });
          } else if (status === 403) {
            getTokenAndRedirect();
          } else if (status === 409 || status === 204 || status === 201) {
            checkDownloadStatus(app.externalId, app.appVersion.externalId);
          }
        },
        error: (code, data) => {
          console.log(code, data);
          if (code === 402) {
            if (
              data.order &&
              data.order.appOrder &&
              !data.order.subscriptionPackageOrder
            ) {
              const orderId = data.order.id;
              const amount = data.order.amount;
              Api().paymentService.requestPayment(orderId, amount, null, {
                success: (data, status) => {
                  Api().paymentService.startPayment(data.paymentToken, {
                    success: (data) => {
                      setGatewayRedirect(data);
                      setShowAdhocLoading(false);

                      document.farazpay.submit();
                    },
                    error: () => {
                      // TODO: handle error
                      setShowAdhocLoading(false);
                    },
                  });
                },
                error: (code, data) => {},
              });
            } else {
              history.push({
                pathname: '/packages',
                state: {
                  checkUserAuthentication: false,
                  hideEnterprise: app.externalNumericId === 12207678,
                  hideAdhoc: false,
                  showCampaignDialog: app.externalNumericId === 12207678,
                  redirectedAppId: app.externalNumericId,
                },
              });
            }
          } else if (code === 403) {
            if (data.processing) {
              setShowAdhocLoading(false);
            }
          }

          // TODO: handle error
        },
      }
    );
  }, [
    app.appVersion.externalId,
    app.appVersion.minOsVersion,
    app.appVersion.unsupported,
    app.externalId,
    app.externalNumericId,
    checkDownloadStatus,
    getTokenAndRedirect,
    history,
    iOSVersion,
    setShowDeveloperModal,
  ]);

  return (
    <>
      <div className="app-downloader-v2 download-button">
        <button
          className="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDownloadClickHandler();
          }}
        >
          {showAdhocLoading ? (
            <PulseLoader size={4} color={lightVariant ? '#027aff' : '#fff'} />
          ) : app.saleType === 'PAID' ? (
            'خرید'
          ) : (
            'دریافت'
          )}
        </button>
      </div>
      {gatewayRedirect ? (
        <div className="gateway-redirection">
          <div dangerouslySetInnerHTML={{ __html: gatewayRedirect }} />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default React.memo(AppDownloaderV2);
