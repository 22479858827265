import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import TabBar from "../../shared/components/TabBar";
import Api, { getImageUrlForFileId } from "../../shared/networking/Api";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import AppWithScreenShotSectionItem from "../featured-apps/components/AppWithScreenShotSectionItem";
import AppDownloader from "../../shared/components/AppDownloader";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trendingSearches: null,
      searchResults: null,
      q: null,
      loading: true,
      searchLoading: false,
      appToDownload: null,
      notFoundError: false,
    };
  }

  componentDidMount() {
    Api(false).appService.trendingSearches({
      success: (result) => {
        this.setState({
          trendingSearches: result,
          loading: false,
        });
      },
      error: () => {
        // TODO: Handle error
      },
    });
  }

  searchKeyword = (title) => {
    this.setState(
      {
        q: title,
        trendingSearches: null,
        searchLoading: true,
      },
      () => {
        this.search();
      }
    );
  };

  search = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { q } = this.state;

    this.setState({
      searchLoading: true,
      notFoundError: false,
    });

    Api(false).appService.search(q, 0, 15, {
      success: (response) => {
        if (response.content.length === 0) {
          this.setState({
            notFoundError: true,
            searchLoading: false,
            loading: false,
            searchResults: null,
          });
        } else {
          this.setState({
            searchResults: response.content,
            searchLoading: false,
          });
        }
      },
      error: (code) => {
        this.setState({
          searchLoading: false,
        });
      },
    });

    const { history, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("q", q);
    history.push({ search: searchParams.toString() });
  };

  onDownloadMenuCanceled = () => {
    this.setState({
      appToDownload: null,
    });
  };

  showSubscriptionPackages = () => {
    const { history } = this.props;
    history.push({
      pathname: "/packages",
      state: { checkUserAuthentication: false },
    });
  };

  downloadRequiresLogin = () => {
    this.setState({
      appToDownload: null,
    });

    const { toggleLoginView } = this.props;

    toggleLoginView(true);
  };

  onDownloadButtonClicked = (app) => {
    this.setState({
      appToDownload: app,
    });
  };

  onSearchFieldChanged = (e) => {
    this.setState({ q: e.target.value });
  };

  render() {
    const {
      loading,
      searchLoading,
      trendingSearches,
      searchResults,
      appToDownload,
      notFoundError,
    } = this.state;

    let trendsList = [];
    let appsList = [];

    if (searchResults) {
      searchResults.forEach((app) => {
        const screenshots = [];
        app.appDetail.screenshots.forEach((screenshot, index) => {
          if (screenshots.length < 3) {
            screenshots.push(
              <div
                style={{
                  backgroundImage: `url(${getImageUrlForFileId(
                    screenshot.fileId,
                    230,
                    498
                  )})`,
                }}
                alt={app.appDetail.title}
                key={screenshot.externalId}
              />
            );
          }
        });
        appsList.push(
          <AppWithScreenShotSectionItem
            app={app}
            key={app.externalId}
            screenshots={screenshots}
            onDownloadButtonClicked={this.onDownloadButtonClicked}
          />
        );
      });
    } else if (trendingSearches) {
      trendingSearches.forEach((res, index) => {
        trendsList.push(
          <a
            className="search-trend"
            key={index}
            onClick={(e) => {
              e.preventDefault();
              this.searchKeyword(res.title);
            }}
          >
            {res.title}
          </a>
        );
      });
    }

    return (
      <div className="search">
        <div className="header">
          <h1>جستجو</h1>
          <form onSubmit={this.search}>
            <input
              type="text"
              className="search-field"
              onChange={this.onSearchFieldChanged}
              enterKeyHint="go"
            />
            <button className="search-button" type="submit">
              <img src="/images/tab-bar-search.svg" alt="" />
            </button>
          </form>
        </div>
        {notFoundError ? (
          <p className="not-found-error">نتیجه ای یافت نشد</p>
        ) : (
          ""
        )}
        {loading ? <LoadingSpinner /> : ""}
        {!searchLoading && !notFoundError ? (
          <div className="trends">
            {searchLoading || (appsList && appsList.length > 0) ? (
              ""
            ) : (
              <h2>بیشترین جستجوها</h2>
            )}
            {trendsList}
          </div>
        ) : notFoundError ? (
          ""
        ) : (
          <LoadingSpinner />
        )}
        <div className="scroller">{searchLoading ? "" : appsList}</div>
        <TabBar />
        <div className="downloader-container">
          {appToDownload ? (
            <AppDownloader
              app={appToDownload}
              onCanceled={this.onDownloadMenuCanceled}
              onPackageRequired={this.showSubscriptionPackages}
              onLoginRequired={this.downloadRequiresLogin}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(Search));
