import React, { Component } from 'react';
import { toEnglishDigits } from '../../shared/StringUtils';
import { PulseLoader } from 'react-spinners';
import classNames from 'classnames';
import Api from '../../shared/networking/Api';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      loading: false,
      error: null,
    };
  }

  setUsername = (username) => {

    this.setState({
      error: null,
    });

    if (username.length === 12) {
      return;
    }
    let phone = toEnglishDigits(username);
    if (phone.startsWith("+")) {
      this.setState({
        error: 'شماره تماس باید با ۰ شروع شود'
      });
      return;
    }

    if (phone && !phone.startsWith("0")) {
      phone = `0${phone}`
    }

    this.setState({username: phone})
  };

  login = (e) => {
    e.preventDefault();

    this.setState({
      error: null,
      loading: true,
    });

    let { username } = this.state;
    const { didSendOtp } = this.props;
    username = `+98${username.substr(1)}`;

    Api(false).authService.generate(username, {
      success: (response) => {
        this.setState({loading: false});
        if (didSendOtp) {
          didSendOtp(username, response.code);
        }
      },
      error: (code) => {
        if (code === 429) {
          this.setState({
            loading: false,
            error: 'خطا به دلیل درخواست های پیاپی',
          });
        } else {
          this.setState({
            loading: false,
            error: 'خطا در ارتباط با سرور',
          });
        }
      },
    });
  };

  render() {
    const { loading, username, error } = this.state;
    const isUsernameValid = username && username.length === 11;
    return (
      <div className="login">
        <div className="phone-form container">
          <h2>ورود به حساب کاربری</h2>
          <p>شماره تماس خود را در قسمت زیر وارد نمایید</p>
          <form onSubmit={this.login}>
            <label htmlFor="phone" className={classNames({error: error !== null})}>{error ? error : 'شماره تماس'}</label>
            <input id="phone" type="tel" onChange={(e) => {this.setUsername(e.target.value)}} value={username}  className={classNames({error: error !== null})} />
            <br />
            <p>عضویت در آی‌اپس به منزله اطلاع از کلیه <a href="/terms" className="terms-button">قوانین</a> و‌ پذیرش آنها می‌باشد.</p>
            <button id="login-submit-button" type="submit" disabled={!isUsernameValid}>{loading ? <PulseLoader css={{'text-align': 'center'}} color="#fff" size="8" /> : 'بعدی'}</button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
