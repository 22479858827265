import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class EnterpriseInstallationTutorial extends Component {

  onBackButtonClicked = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.goBack();
  };

  render() {
    return (
      <div className="enterprise-installation-tutorial">
        <div className="view-navigation-bar">
          <a href="#" onClick={this.onBackButtonClicked}><img src="/images/icon-back.svg" alt="" /></a>
          <h1 className="title">آموزش نصب اینترپرایز</h1>
        </div>
        <div className="sections">
          <div className="section">
            <h1>نصب برنامه</h1>
            <p>ابتدا اپلیکیشن مورد نظر را انتخاب و نصب اینترپرایز را انتخاب کنید. پس سپس همانند تصویر زیر گزینه Install را بزنید.</p>
            <img src="/images/enterprise-tutorial-1.png" alt="" />
          </div>
          <div className="section">
            <h1>ورود به تنظیمات</h1>
            <p>ابتدا اپلیکیشن مورد نظر را انتخاب و نصب اینترپرایز را انتخاب کنید. پس سپس همانند تصویر زیر گزینه Install را بزنید.</p>
            <a href="" className="btn btn-primary">ورود سریع به تنظیمات</a>
            <p>پس از انتخاب ورود سریع گزینه Allow را انتخاب کنید.</p>
            <img src="/images/enterprise-tutorial-2.png" alt="" />
          </div>
          <div className="section">
            <h1>ورود به Device Management</h1>
            <p>سپس وارد قسمت Device Management شوید. دقت کنید در بعضی از نسخه های iOS نام این قسمت Profiles می باشد.</p>
            <img src="/images/enterprise-tutorial-3.png" alt="" />
          </div>
          <div className="section">
            <h1>انتخاب پروفایل مربوطه</h1>
            <p>در این قسمت پروفایلی که مربوط به برنامه های نصب شده شما می باشد را انتخاب کنید.</p>
            <img src="/images/enterprise-tutorial-4.png" alt="" />
          </div>
          <div className="section">
            <h1>انتخاب گزینه Trust</h1>
            <p>در این مرحله همانند تصویر گزینه Trust را انتخاب کنید.</p>
            <img src="/images/enterprise-tutorial-5.png" alt="" />
          </div>
          <div className="section">
            <h1>انتخاب گزینه Trust</h1>
            <p>یک بار دیگر گزینه تراست را از قسمت مشخص شده انتخاب کنید.</p>
            <img src="/images/enterprise-tutorial-6.png" alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(EnterpriseInstallationTutorial);
