import React from 'react';
import { Link } from 'react-router-dom';

const PurchasePackageBottomActionView = () => (
  <Link to={{
    pathname: "/packages",
    state: { checkUserAuthentication: true }
  }}>
    <div id="purchase-package-action-view" className="purchase-package-action">
      تهیه اشتراک و دانلود نامحدود برنامه و بازی
    </div>
  </Link>
);

export default PurchasePackageBottomActionView;
